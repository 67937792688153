<template>
  <Setting />
</template>

<script>
import Setting from "@/views/retailer/Setting.vue";
export default {
  components: { Setting },
};
</script>

<style lang="scss" scoped></style>
