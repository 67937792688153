<template>
  <div class="plan_wrapper plan-card-section" v-if="plan.isFeatured != true">
    <div v-if="plan.name == 'Pro'" class="best-value-container pt-6">
      <p class="text-center best-value">BEST VALUE</p>
    </div>

    <h2 class="plan-title">{{ plan.name }}</h2>

    <div class="price-section">
      <span v-if="plan.monthlyPrice == 0" class="price"> Free </span>
      <span v-if="plan.monthlyPrice !== 0" class="price">
        {{
          priceChangeValue
            ? `${plan.currency_symbol}${plan.yearlyPrice / 12} /mo`
            : `${plan.currency_symbol}${plan.monthlyPrice} /mo`
        }}
      </span>
    </div>
    <div class="discount-container">
      <span style="opacity: 0" class="discount" v-if="plan.monthlyPrice == 0"
        >Save 30% annually
      </span>
      <span
        v-if="plan.monthlyPrice > 0"
        :class="
          plan.monthlyPrice > 0 && !priceChangeValue ? 'discountDisabled' : ''
        "
        class="discount"
      >
        Save
        {{ calculateDiscount(plan.yearlyPrice, plan.monthlyPrice) }}% annually
      </span>
    </div>
    <p class="desc">
      {{ plan.description }}
    </p>
    <div v-if="plans.current_plan_expired" class="button-wrapper">
      <span
        class="your-plan"
        :class="{
          'your-plan-pro': plan.userSub && plan.name == 'Pro',
          'your-plan-starter':
            (plan.userSub && plan.name == 'Starter') ||
            (plan.userSub && plan.name == 'Business'),
        }"
      >
        To get started, please cancel your previous subscription first.
      </span>
    </div>
    <div v-else class="button-wrapper">
      <span
        class="your-plan"
        :class="{
          'your-plan-pro': plan.userSub && plan.name == 'Pro',
          'your-plan-starter':
            (plan.userSub && plan.name == 'Starter') ||
            (plan.userSub && plan.name == 'Business'),
        }"
        v-if="plan.userSub"
      >
        Your Current Plan
      </span>
      <!-- <PlanSubUpgradeModal
        v-else-if="toUpgrade(plan.order)"
        :plan="plan"
        :key="plan.order"
        :showButton="true"
        :upgradePlanDialog="upgradePlanDialog"
        @closeUpgradePlanDialog="upgradePlanDialog = false"
        @OpenUpgradePlan="upgradePlanDialog = true"
      >
        <span class="try"> UPGRADE YOUR PLAN </span>
      </PlanSubUpgradeModal> -->
      <PlanSubDowngradeModal
        v-else
        :plan="plan"
        :key="plan.orders"
        :downgradePlanDialog="downgradePlanDialog"
        @closeDowngradePlanDialog="downgradePlanDialog = false"
        @OpenDowngradePlan="downgradePlanDialog = true"
      >
        <span class="try">Start Now </span>
      </PlanSubDowngradeModal>
    </div>
    <div class="features">
      <div
        v-for="feature in plan.support.split(',')"
        :key="feature"
        class="each"
      >
        <span class="icon icon-green align-self-start">
          <span class="material-symbols-outlined icon icon-green">
            check_circle
          </span>
        </span>
        <span class="feature">{{ feature }} </span>
      </div>
    </div>
  </div>

  <div class="plan_wrapper featured" v-else-if="plan.isFeatured == true">
    <div v-if="plan.name == 'Pro'" class="best-value-container pt-6">
      <p class="text-center best-value">BEST VALUE</p>
    </div>
    <h2 class="plan-title">{{ plan.name }}</h2>
    <div class="price-section">
      <span v-if="plan.monthlyPrice == 0" class="price"> Free </span>
      <span v-if="plan.monthlyPrice !== 0" class="price">
        {{
          priceChangeValue
            ? `${plan.currency_symbol}${plan.yearlyPrice / 12} /mo`
            : `${plan.currency_symbol}${plan.monthlyPrice} /mo`
        }}
      </span>
    </div>
    <div class="discount-container">
      <span style="opacity: 0" class="discount" v-if="plan.monthlyPrice == 0"
        >Save 30% annually
      </span>
      <span
        v-if="plan.monthlyPrice > 0"
        :class="
          plan.monthlyPrice > 0 && !priceChangeValue ? 'discountDisabled' : ''
        "
        class="discount"
      >
        Save
        {{ calculateDiscount(plan.yearlyPrice, plan.monthlyPrice) }}% annually
      </span>
    </div>
    <p class="desc">
      {{ plan.description }}
    </p>
    <div v-if="plans.current_plan_expired" class="button-wrapper">
      <span
        class="your-plan"
        :class="{
          'your-plan-pro': plan.name == 'Pro',
          'your-plan-starter':
            (plan.userSub && plan.name == 'Starter') ||
            (plan.userSub && plan.name == 'Business'),
        }"
      >
        To get started, please cancel your previous subscription first.
      </span>
    </div>
    <div v-else class="button-wrapper">
      <span class="your-plan" v-if="plan.userSub">Your Current Plan</span>
      <!-- <PlanSubUpgradeModal
        v-else-if="toUpgrade(plan.order)"
        :plan="plan"
        :key="plan.order"
        :upgradePlanDialog="upgradePlanDialog"
        @closeUpgradePlanDialog="upgradePlanDialog = false"
        @OpenUpgradePlan="upgradePlanDialog = true"
      >
        <span class="try"> UPGRADE YOUR PLAN</span>
      </PlanSubUpgradeModal> -->
      <PlanSubDowngradeModal
        v-else
        :plan="plan"
        :key="plan.orders"
        :downgradePlanDialog="downgradePlanDialog"
        @closeDowngradePlanDialog="downgradePlanDialog = false"
        @OpenDowngradePlan="downgradePlanDialog = true"
      >
        <span class="try">Start Now</span>
      </PlanSubDowngradeModal>
    </div>
    <div class="features">
      <div class="each" v-if="plan.productsLimit">
        <span class="icon icon-green align-self-start">
          <span class="material-symbols-outlined icon icon-green">
            check_circle
          </span>
        </span>
        <span class="feature">
          Import
          {{ plan.productsLimit != 100000 ? plan.productsLimit : "Unlimited" }}
          products
        </span>
      </div>
      <div class="each" v-if="plan.checkoutLimit">
        <span class="icon icon-green align-self-start">
          <span class="material-symbols-outlined icon icon-green">
            check_circle
          </span>
        </span>
        <span class="feature"
          >{{
            plan.checkoutLimit != 100000 ? plan.checkoutLimit : "Unlimited"
          }}
          checkouts <sub v-if="plan.monthlyPrice !== 0"></sub
        ></span>
      </div>

      <div class="each" v-if="plan.name === 'Pro'">
        <span class="icon icon-green align-self-start">
          <span class="material-symbols-outlined icon icon-green">
            check_circle
          </span>
        </span>
        <span class="feature"> High priority support </span>
      </div>

      <div v-if="plan.name == 'Business'" class="d-flex flex-column">
        <div class="each d-flex align-items-center align-self-start">
          <span class="icon icon-green align-self-start">
            <span class="material-symbols-outlined icon icon-green">
              check_circle
            </span>
          </span>
          <span class="feature"> Dedicated account manager </span>
        </div>
        <div class="each d-flex align-items-center">
          <span class="icon icon-green align-self-start">
            <span class="material-symbols-outlined icon icon-green">
              check_circle
            </span>
          </span>

          <span class="feature"> Automated bulk checkouts </span>
        </div>
        <div class="each d-flex align-items-center">
          <span class="icon icon-green align-self-start">
            <span class="material-symbols-outlined icon icon-green">
              check_circle
            </span>
          </span>

          <span class="feature">
            Weekly curated list of high-potential products tailored for your
            target market,
          </span>
        </div>
      </div>
      <div class="each" v-if="plan.name === 'Business'">
        <span class="icon icon-green align-self-start">
          <span class="material-symbols-outlined icon icon-green">
            check_circle
          </span>
        </span>
        <span class="feature"> Early access to premium features </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// components
import PlanSubUpgradeModal from "@/components/retailer/modals/planSubUpgradeModal.vue";

import PlanSubDowngradeModal from "@/components/retailer/modals/planSubDowngradeModal.vue";
import SimpleBtn from "@/components/bases/SimpleBtn.vue";
import LoadingBtn from "@/components/bases/Loading.vue";
// Services
import SettingService from "@/services/SettingService";

export default {
  name: "PlanCard",
  components: {
    PlanSubUpgradeModal,
    PlanSubDowngradeModal,
    SimpleBtn,
    LoadingBtn,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    priceChangeValue: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentTab: "plans",
      addingCardErrorMessage: "",

      upgradePlanDialog: false,
      downgradePlanDialog: false,

      upgradeLoading: false,
      downgradeLoading: false,
    };
  },
  created() {
    this.planPicked = "yearly";
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {
    ...mapGetters({
      plans: "retailer/listOfPlansSubscription",
      CurrentUserInformation: "retailer/currentUserInformation",
    }),
    saving() {
      const saved =
        (this.plan.monthlyPrice * 12 - this.plan.yearlyPrice * 12) /
        this.plan.monthlyPrice;
      return Math.trunc(saved);
    },
  },
  methods: {
    ...mapActions(["retailer/getPlansSubscription"]),

    toUpgrade(planOrder) {
      const SubedPlan = this.plans.data.find((plan) => plan?.userSub);

      if (SubedPlan) {
        const found = this.plans.data.find(
          (plan) => !plan.userSub && SubedPlan.order < planOrder
        );
         
        if (found) return true;
        else return false;
      }
    },

    closeUpgradePlanDialog() {
      this.upgradePlanDialog = false;
    },

    closeDowngradePlanDialog() {
      this.downgradePlanDialog = false;
    },

    cancelMembership() {
      this.downgradeLoading = true;
      SettingService.cancelMembership()
        .then(async (response) => {
          console.log(response);
          setTimeout(async () => {
            await this["retailer/getPlansSubscription"]();
            this.downgradeLoading = false;
            this.downgradePlanDialog = false;
          }, 2500);
        })
        .catch((e) => {
          this.downgradeLoading = false;

          console.log("cancel plan", e);
        });
    },
    calculateDiscount(yearlyPrice, monthlyPrice) {
      const totalYearlyPrice = yearlyPrice;
      const totalMonthlyPrice = monthlyPrice * 12;

      const result =
        100 - Math.floor((totalYearlyPrice * 100) / totalMonthlyPrice);
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";
.your-plan-pro {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435160;
  padding: 18px;
  border-radius: 10px;
  background: #e4e7ec;
  display: block;
  max-width: 360px;
  margin: 21px 0;
  width: 100%;
  margin-top: 44px;
}
.vgang_btn {
  padding: 16px !important;
}
.best-value-container {
  position: absolute;
  top: -67px;
  background: #008f4a;
  left: 0;
  right: 0;
  padding: 8px 21px;
  padding-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @include md {
    position: relative;
    top: unset;
    padding-top: 8px !important;
  }
}
.best-value {
  background-color: #cde9ce;
  color: #008f4a;
  padding: 10px 21px;
  border-radius: 14px;
  font-size: 15px;
  font-weight: 700;
}
.green-bg {
  @include xl {
    row-gap: 34px;
  }
  padding: 0 !important;
  background-color: #008f4a !important;

  // height: 793px !important;
  padding-top: 41px !important;
  @include lg {
    margin-top: 55px !important;
  }
  .plan-title,
  .price,
  .desc,
  .title {
    color: #fefefe !important;
  }
  .icon-green {
    color: #fff;
  }

  .custom-button {
    background-color: #fff;
    color: #008f4a !important;
  }
  .features .feature img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
      hue-rotate(266deg) brightness(106%) contrast(105%) !important;
  }
  .discount {
    color: #fefefe !important;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
  }
}
.discount-container {
  margin: 24px 0;
}
.discountDisabled {
  opacity: 0;
}
.plan-card-section {
  .button-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 0 !important;
    position: relative !important;

    &.tag {
      color: #ffffff;
    }
    .custom-button {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ebf2ff;
      border: 1px solid #008f4a;
      padding: 16px;
      border-radius: 10px;
      max-width: 360px;
    }
    .your-plan {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #435160;
      padding: 18px;
      border-radius: 10px;
      background: #e4e7ec;
      display: block;
      max-width: 360px;
      margin: 21px 0;
      width: 100%;
    }

    .your-plan-starter {
      margin-top: 44px;
    }
  }

  .plan_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 16px;
    &featured {
      border-radius: 8px;
      background-color: #008f4a;
      flex-wrap: wrap;
      margin: 0;
    }
  }
  #cancel-membership-modal {
    .cancel-membership-modal-body {
      padding: 24px;
    }
    .cancel-membership-modal-footer {
      display: flex;
      justify-content: flex-end;
      width: auto;
      button {
        width: 90px;
        margin-right: 12px;
      }
    }
  }
  #card-element-errors {
    color: $red-500;
    padding: 24px 0;
  }

  .plan_wrapper_featured {
    //display: flex;
    flex-direction: column;
    align-items: flex-start;
    //padding: 24px;
    gap: 24px;

    border-radius: 8px;
    background-color: #008f4a;
    flex-wrap: wrap;
    margin: 0;
  }
  .subs_card {
    max-width: 640px;
    overflow: hidden;
    .card_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      background-color: $grey_100;
      height: 92px;
      h2 {
        font-size: $font_size_base * 2;
        font-weight: bold;
      }
    }
    .card_body {
      .subs_option {
        border-radius: 8px;
        width: 285px;
        border: 1px solid $primary;
        &.active {
          background-color: $green-50;
          border: 1px solid $primary;
        }
      }
      .subs_message {
        color: $primary;
      }
      .text_header_lg {
        font-weight: bold;
        font-size: $font_size_base + 0.8rem;
      }
      .text_header_sm {
        font-weight: 600;
        font-size: $font_size_base + 0.4rem;
      }
      .text_red {
        color: $red-500;
      }
      .text_header {
        font-weight: 600;
        font-size: $font_size_base + 0.6rem;
      }
      .text_header {
        font-weight: 600;
        font-size: $font_size_base + 0.6rem;
      }
      .link_text {
        font-weight: normal;
        color: $primary;
        text-decoration: underline;

        &:hover {
          font-weight: 600;
          cursor: pointer;
        }
      }
      .downgrade_text {
        &:hover {
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
  }
  .payment-simple {
    width: 100%;
    border: solid 1px $grey_300;
    padding: 10px;
    border-radius: 8px;
  }
}
</style>
