var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.plan.isFeatured != true)?_c('div',{staticClass:"plan_wrapper plan-card-section"},[(_vm.plan.name == 'Pro')?_c('div',{staticClass:"best-value-container pt-6"},[_c('p',{staticClass:"text-center best-value"},[_vm._v("BEST VALUE")])]):_vm._e(),_c('h2',{staticClass:"plan-title"},[_vm._v(_vm._s(_vm.plan.name))]),_c('div',{staticClass:"price-section"},[(_vm.plan.monthlyPrice == 0)?_c('span',{staticClass:"price"},[_vm._v(" Free ")]):_vm._e(),(_vm.plan.monthlyPrice !== 0)?_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.priceChangeValue ? `${_vm.plan.currency_symbol}${_vm.plan.yearlyPrice / 12} /mo` : `${_vm.plan.currency_symbol}${_vm.plan.monthlyPrice} /mo`)+" ")]):_vm._e()]),_c('div',{staticClass:"discount-container"},[(_vm.plan.monthlyPrice == 0)?_c('span',{staticClass:"discount",staticStyle:{"opacity":"0"}},[_vm._v("Save 30% annually ")]):_vm._e(),(_vm.plan.monthlyPrice > 0)?_c('span',{staticClass:"discount",class:_vm.plan.monthlyPrice > 0 && !_vm.priceChangeValue ? 'discountDisabled' : ''},[_vm._v(" Save "+_vm._s(_vm.calculateDiscount(_vm.plan.yearlyPrice, _vm.plan.monthlyPrice))+"% annually ")]):_vm._e()]),_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.plan.description)+" ")]),(_vm.plans.current_plan_expired)?_c('div',{staticClass:"button-wrapper"},[_c('span',{staticClass:"your-plan",class:{
        'your-plan-pro': _vm.plan.userSub && _vm.plan.name == 'Pro',
        'your-plan-starter':
          (_vm.plan.userSub && _vm.plan.name == 'Starter') ||
          (_vm.plan.userSub && _vm.plan.name == 'Business'),
      }},[_vm._v(" To get started, please cancel your previous subscription first. ")])]):_c('div',{staticClass:"button-wrapper"},[(_vm.plan.userSub)?_c('span',{staticClass:"your-plan",class:{
        'your-plan-pro': _vm.plan.userSub && _vm.plan.name == 'Pro',
        'your-plan-starter':
          (_vm.plan.userSub && _vm.plan.name == 'Starter') ||
          (_vm.plan.userSub && _vm.plan.name == 'Business'),
      }},[_vm._v(" Your Current Plan ")]):_c('PlanSubDowngradeModal',{key:_vm.plan.orders,attrs:{"plan":_vm.plan,"downgradePlanDialog":_vm.downgradePlanDialog},on:{"closeDowngradePlanDialog":function($event){_vm.downgradePlanDialog = false},"OpenDowngradePlan":function($event){_vm.downgradePlanDialog = true}}},[_c('span',{staticClass:"try"},[_vm._v("Start Now ")])])],1),_c('div',{staticClass:"features"},_vm._l((_vm.plan.support.split(',')),function(feature){return _c('div',{key:feature,staticClass:"each"},[_vm._m(0,true),_c('span',{staticClass:"feature"},[_vm._v(_vm._s(feature)+" ")])])}),0)]):(_vm.plan.isFeatured == true)?_c('div',{staticClass:"plan_wrapper featured"},[(_vm.plan.name == 'Pro')?_c('div',{staticClass:"best-value-container pt-6"},[_c('p',{staticClass:"text-center best-value"},[_vm._v("BEST VALUE")])]):_vm._e(),_c('h2',{staticClass:"plan-title"},[_vm._v(_vm._s(_vm.plan.name))]),_c('div',{staticClass:"price-section"},[(_vm.plan.monthlyPrice == 0)?_c('span',{staticClass:"price"},[_vm._v(" Free ")]):_vm._e(),(_vm.plan.monthlyPrice !== 0)?_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.priceChangeValue ? `${_vm.plan.currency_symbol}${_vm.plan.yearlyPrice / 12} /mo` : `${_vm.plan.currency_symbol}${_vm.plan.monthlyPrice} /mo`)+" ")]):_vm._e()]),_c('div',{staticClass:"discount-container"},[(_vm.plan.monthlyPrice == 0)?_c('span',{staticClass:"discount",staticStyle:{"opacity":"0"}},[_vm._v("Save 30% annually ")]):_vm._e(),(_vm.plan.monthlyPrice > 0)?_c('span',{staticClass:"discount",class:_vm.plan.monthlyPrice > 0 && !_vm.priceChangeValue ? 'discountDisabled' : ''},[_vm._v(" Save "+_vm._s(_vm.calculateDiscount(_vm.plan.yearlyPrice, _vm.plan.monthlyPrice))+"% annually ")]):_vm._e()]),_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.plan.description)+" ")]),(_vm.plans.current_plan_expired)?_c('div',{staticClass:"button-wrapper"},[_c('span',{staticClass:"your-plan",class:{
        'your-plan-pro': _vm.plan.name == 'Pro',
        'your-plan-starter':
          (_vm.plan.userSub && _vm.plan.name == 'Starter') ||
          (_vm.plan.userSub && _vm.plan.name == 'Business'),
      }},[_vm._v(" To get started, please cancel your previous subscription first. ")])]):_c('div',{staticClass:"button-wrapper"},[(_vm.plan.userSub)?_c('span',{staticClass:"your-plan"},[_vm._v("Your Current Plan")]):_c('PlanSubDowngradeModal',{key:_vm.plan.orders,attrs:{"plan":_vm.plan,"downgradePlanDialog":_vm.downgradePlanDialog},on:{"closeDowngradePlanDialog":function($event){_vm.downgradePlanDialog = false},"OpenDowngradePlan":function($event){_vm.downgradePlanDialog = true}}},[_c('span',{staticClass:"try"},[_vm._v("Start Now")])])],1),_c('div',{staticClass:"features"},[(_vm.plan.productsLimit)?_c('div',{staticClass:"each"},[_vm._m(1),_c('span',{staticClass:"feature"},[_vm._v(" Import "+_vm._s(_vm.plan.productsLimit != 100000 ? _vm.plan.productsLimit : "Unlimited")+" products ")])]):_vm._e(),(_vm.plan.checkoutLimit)?_c('div',{staticClass:"each"},[_vm._m(2),_c('span',{staticClass:"feature"},[_vm._v(_vm._s(_vm.plan.checkoutLimit != 100000 ? _vm.plan.checkoutLimit : "Unlimited")+" checkouts "),(_vm.plan.monthlyPrice !== 0)?_c('sub'):_vm._e()])]):_vm._e(),(_vm.plan.name === 'Pro')?_c('div',{staticClass:"each"},[_vm._m(3),_c('span',{staticClass:"feature"},[_vm._v(" High priority support ")])]):_vm._e(),(_vm.plan.name == 'Business')?_c('div',{staticClass:"d-flex flex-column"},[_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e(),(_vm.plan.name === 'Business')?_c('div',{staticClass:"each"},[_vm._m(7),_c('span',{staticClass:"feature"},[_vm._v(" Early access to premium features ")])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon icon-green align-self-start"},[_c('span',{staticClass:"material-symbols-outlined icon icon-green"},[_vm._v(" check_circle ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon icon-green align-self-start"},[_c('span',{staticClass:"material-symbols-outlined icon icon-green"},[_vm._v(" check_circle ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon icon-green align-self-start"},[_c('span',{staticClass:"material-symbols-outlined icon icon-green"},[_vm._v(" check_circle ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon icon-green align-self-start"},[_c('span',{staticClass:"material-symbols-outlined icon icon-green"},[_vm._v(" check_circle ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"each d-flex align-items-center align-self-start"},[_c('span',{staticClass:"icon icon-green align-self-start"},[_c('span',{staticClass:"material-symbols-outlined icon icon-green"},[_vm._v(" check_circle ")])]),_c('span',{staticClass:"feature"},[_vm._v(" Dedicated account manager ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"each d-flex align-items-center"},[_c('span',{staticClass:"icon icon-green align-self-start"},[_c('span',{staticClass:"material-symbols-outlined icon icon-green"},[_vm._v(" check_circle ")])]),_c('span',{staticClass:"feature"},[_vm._v(" Automated bulk checkouts ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"each d-flex align-items-center"},[_c('span',{staticClass:"icon icon-green align-self-start"},[_c('span',{staticClass:"material-symbols-outlined icon icon-green"},[_vm._v(" check_circle ")])]),_c('span',{staticClass:"feature"},[_vm._v(" Weekly curated list of high-potential products tailored for your target market, ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon icon-green align-self-start"},[_c('span',{staticClass:"material-symbols-outlined icon icon-green"},[_vm._v(" check_circle ")])])
}]

export { render, staticRenderFns }