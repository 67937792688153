<template>
  <div>
    <v-dialog
      v-model="upgradePlanDialogCopy"
      :retain-focus="false"
      style="z-index: 20001"
      max-width="640"
      persistent
      eager
      class="plan_sub_upgrade_modal"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-if="plan.isFeatured == false && showButton"
          class="vgang_btn btn_green"
          :class="{
            'Business-btn': plan.name == 'Business' && !headerBtn,
            'vgang-btn-green-box': plan.name == 'Pro' && !headerBtn,
          }"
          v-bind="attrs"
          v-on="on"
          @click="OpenUpgradePlan()"
        >
          <slot></slot>
        </button>
        <button
          v-if="plan.isFeatured == true && showButton"
          class="vgang_btn btn_green"
          :class="{
            'Business-btn': plan.name == 'Business',
            'vgang-btn-green-box': plan.name == 'Pro',
          }"
          v-bind="attrs"
          v-on="on"
          @click="OpenUpgradePlan()"
        >
          <slot></slot>
        </button>
      </template>
      <div class="spinner-container" style="" v-if="isLoading">
        <v-progress-circular indeterminate color="green"></v-progress-circular>
      </div>

      <v-card v-if="!isLoading" class="subs_card">
        <div class="card_header d-flex align-center justify-space-between">
          <h2>Get {{ planPicked }} {{ plan.name }} Plan</h2>
          <BaseBtn
            class="vgang_btn-modal btn_green"
            @clicked="!upgradeLoading && closeUpgradePlanDialog()"
            :icon="'close'"
          />
        </div>
        <v-card class="card_body pa-6">
          <div class="d-flex plans-options align-center justify-space-between">
            <div
              class="subs_option d-flex justify-space-between text-left pa-6 pr-0"
              :class="{
                active: planPicked == 'yearly',
              }"
            >
              <div class="d-inline">
                <span class="text_header">Yearly </span>
                <span style="font-size: 14px"
                  >{{ (plan.yearlyPrice / 12) | toCurrency }}/mo</span
                >
              </div>
              <label
                class="custom-radio-2"
                :for="planPicked"
                :key="plan.order + 'yearly'"
              >
                <input
                  type="radio"
                  id="yearly"
                  :name="`plan-${plan.name}`"
                  value="yearly"
                  v-model="planPicked"
                />
                <span class="radiomark-2"></span>
              </label>
            </div>
            <div
              class="subs_option d-flex justify-space-between text-left pa-6 pr-0"
              :class="{
                active: planPicked == 'monthly',
              }"
            >
              <div class="d-inline">
                <span class="text_header">Monthly</span>
                <span style="font-size: 14px">
                  {{ plan.monthlyPrice | toCurrency }}/mo
                </span>
              </div>
              <label
                class="custom-radio-2"
                :for="planPicked"
                :key="plan.order + 'monthly'"
              >
                <input
                  type="radio"
                  id="monthly"
                  :name="`plan-${plan.name}`"
                  value="monthly"
                  v-model="planPicked"
                />
                <span class="radiomark-2"></span>
              </label>
            </div>
          </div>
          <div
            v-if="planPicked === 'yearly'"
            class="subs_message text-center py-4 mb-6"
          >
            You save {{ saving }}% and get
            {{
              plan.checkoutLimit != 100000 ? plan.checkoutLimit : "Unlimited"
            }}
            checkouts per year
          </div>

          <div>
            <StripeElements
              :stripe-key="stripeKey"
              #default="{ elements }"
              :ref="`${plan.name}-elms`"
            >
              <div class="text_header mt-3 mb-3">Card Number</div>
              <div class="card_input_wrapper">
                <StripeElement
                  type="cardNumber"
                  :elements="elements"
                  :ref="`${plan.name}-cardNumber`"
                />
              </div>

              <div class="card_exp_cvc_wrapper">
                <div class="card_exp_cvc">
                  <div class="text_header mt-3 mb-3">Card Expire</div>
                  <div class="card_input_wrapper">
                    <StripeElement
                      type="cardExpiry"
                      :elements="elements"
                      :ref="`${plan.name}-cardExpiry`"
                    />
                  </div>
                </div>
                <div class="card_exp_cvc">
                  <div class="text_header mt-3 mb-3">Card CVC</div>
                  <div class="card_input_wrapper">
                    <StripeElement
                      type="cardCvc"
                      :elements="elements"
                      :ref="`${plan.name}-cardCvc`"
                    />
                  </div>
                </div>
              </div>
            </StripeElements>
            <div class="card_exp_cvc">
              <div class="text_header-Coupon mt-3 mb-3">
                Discount code (optional)
              </div>
              <div class="card_input_wrapper">
                <input
                  class="card-input"
                  v-model="couponCode"
                  placeholder="Enter discount code"
                />
              </div>
            </div>
          </div>
          <div id="card-element-errors" role="alert" class="text-center"></div>
          <div class="text_header_lg my-3">{{ plan.name }} plan</div>
          <div v-if="planPicked == 'yearly'">
            <div class="d-flex align-center justify-space-between my-3">
              <div class="text_header">
                {{ (plan.yearlyPrice / 12) | toCurrency }}/month x 12 Months
              </div>
              <div class="link_text" @click="!upgradeLoading && changeplan()">
                change plan
              </div>
            </div>
            <div class="d-flex align-center justify-space-between my-3">
              <div class="text_header">Total amount</div>
              <div class="text_header">
                {{ Math.round(plan.yearlyPrice) | toCurrency }}
              </div>
            </div>
          </div>
          <div v-else-if="(planPicked = 'monthly')">
            <div class="d-flex align-center justify-space-between my-3">
              <div class="text_header">
                {{
                  (Math.round(plan.monthlyPrice * 100) / 100) | toCurrency
                }}/month
              </div>
              <div class="link_text" @click="!upgradeLoading && changeplan()">
                change plan
              </div>
            </div>
            <div class="d-flex align-center justify-space-between my-3">
              <div class="text_header">Total amount</div>
              <div class="text_header">
                {{ (Math.round(plan.monthlyPrice * 100) / 100) | toCurrency }}
              </div>
            </div>
          </div>

          <div class="d-flex justify-center">
            <!-- <LoadingBtn
              class="vgang_btn btn_green btn_full_width mt-5"
              :loading="upgradeLoading"
              @clicked="
                (upgradeLoading = true),
                  pay(plan.name, plan.monthlyPriceID, plan.yearlyPriceID)
              "
              >Upgrade to {{ plan.name }}</LoadingBtn> -->
          </div>
          <div class="text-center mt-3"></div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SimpleBtn from "@/components/bases/SimpleBtn.vue";
import BaseBtn from "@/components/bases/BaseBtn.vue";
import LoadingBtn from "@/components/bases/Loading.vue";
// Services
import SettingService from "@/services/SettingService";
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";
export default {
  name: "PlanSubscriptionUpgrade",
  components: {
    SimpleBtn,
    BaseBtn,
    StripeElements,
    StripeElement,
    LoadingBtn,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    upgradePlanDialog: {
      type: Boolean,
      required: true,
    },
    headerBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      addingCardErrorMessage: "",
      planPicked: "yearly",
      upgradeLoading: false,
      currentTab: "plansSubcription",
      isLoading: true,
      couponCode: null,
      stripeKey: process.env.VUE_APP_STRIPE_APP_KEY || "", // test key, don't hardcode
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: "",
        },
        // https://stripe.com/docs/stripe.js#element-options
      },
    };
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {
    ...mapGetters({
      plans: "retailer/listOfPlansSubscription",
      CurrentUserInformation: "retailer/currentUserInformation",
    }),
    upgradePlanDialogCopy() {
      return this.upgradePlanDialog;
    },
    saving() {
      const saved =
        100 - (this.plan.yearlyPrice * 100) / (this.plan.monthlyPrice * 12);

      return Math.trunc(saved);
    },
  },
  mounted() {
    const stripeScriptSrc = "https://js.stripe.com/v3/";

    this.isLoading = true;
    const script = document.createElement("script");
    script.src = stripeScriptSrc;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => {
      this.isLoading = false;
      // The Stripe script has loaded, you can now use Stripe functionality
    };
  },
  methods: {
    ...mapActions([
      "retailer/getPlansSubscription",
      "alert/clear",
      "alert/newAlert",
    ]),
    changeplan() {
      let routeName = this.$route.name;

      let data = {
        currentTab: "plansSubcription",
      };
      if (routeName === "SettingRetailer") {
        this.$emit("closeUpgradePlanDialog", false);
      } else {
        this.$router
          .push({ name: "SettingRetailer", params: { data } })
          .catch((error) => {});
      }
    },
    OpenUpgradePlan() {
      this.$emit("OpenUpgradePlan", true);
    },
    closeUpgradePlanDialog() {
      this.$emit("closeUpgradePlanDialog", false);
    },

    pay(planName, monthlyPriceID, yearlyPriceID) {
      this.upgradeLoading = true;
      const purchaseID =
        this.planPicked === "monthly" ? monthlyPriceID : yearlyPriceID;
      // ref in template
      const groupComponent = this.$refs[`${planName}-elms`];
      const cardComponent = this.$refs[`${planName}-cardNumber`];
      const cardExpiryComponent = this.$refs[`${planName}-cardExpiry`];
      const cardCvcComponent = this.$refs[`${planName}-cardCvc`];
      // Get stripe element
      const cardElement = cardComponent.stripeElement;
      // Access instance methods, e.g. createToken()
      groupComponent.instance.createToken(cardElement).then((result) => {
        if (result.error) {
          this["alert/newAlert"]({
            type: "error",
            message: result.error.message,
          });
          setTimeout(async () => {
            this["alert/clear"]();
          }, 3000);

          let displayError = document.getElementById("card-element-errors");
          displayError.textContent = result.error.message;
          this.upgradeLoading = false;
        } else {
          // handle the token
          SettingService.getClientSecret(purchaseID, this.couponCode)
            .then((response) => {
              const clientSecret = response.data.data.clientSecret;
              groupComponent.instance
                .confirmCardPayment(clientSecret, {
                  payment_method: {
                    card: cardElement,
                    billing_details: {
                      name: this.CurrentUserInformation.email,
                    },
                  },
                })
                .then(async (result) => {
                  if (result.error) {
                    this["alert/newAlert"]({
                      type: "error",
                      message: result.error.message,
                    });
                    setTimeout(async () => {
                      this["alert/clear"]();
                    }, 3000);
                    let displayError = document.getElementById(
                      "card-element-errors"
                    );
                    displayError.textContent = result.error.message;
                    this.upgradeLoading = false;
                  } else {
                    setTimeout(async () => {
                      await this["retailer/getPlansSubscription"]();
                      this.upgradeLoading = false;
                      this.$emit("planUpgraded");
                      this.$emit("closeUpgradePlanDialog", false);
                      this["alert/newAlert"]({
                        type: "success",
                        message: "Plan upgraded successfully",
                      });
                      setTimeout(async () => {
                        this["alert/clear"]();
                      }, 3000);
                    }, 2500);
                  }
                })
                .catch((e) => {
                  this["alert/newAlert"]({
                    type: "error",
                    message: "Sonthing went wrong. Please try again later",
                  });
                  setTimeout(async () => {
                    this["alert/clear"]();
                  }, 3000);
                  console.log("error1", e);
                  this.upgradeLoading = false;
                });
            })
            .catch((e) => {
              console.log("error2", e);
              this["alert/newAlert"]({
                type: "error",
                message: "Sonthing went wrong. Please try again later",
              });
              setTimeout(async () => {
                this["alert/clear"]();
              }, 3000);
              this.upgradeLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}
.plans-options {
  @include md {
    gap: 14px;
  }
}
.plan_sub_upgrade_modal {
  min-height: 400px;
}
.text_header-Coupon {
  font-size: 14px;
  font-weight: 600;
  margin-top: 34px !important;
  color: #000000de !important;
}
.card_input_wrapper {
  .card-input {
    height: 100%;
    width: 100%;
    outline: none;
    &::placeholder {
      font-size: 12px;
    }
  }
}
#card-element-errors {
  color: $red-500;
  padding: 24px 0;
}
.green-bg {
  .vgang_btn {
    background: #fff !important;
    color: #008f4a !important;
  }
}
.vgang_btn {
  padding: 10px;
  margin: 21px 0;
  margin-top: 19px;
}
.vgang_btn-modal {
  width: auto !important;
  @include sm {
    padding: 8px;
  }
}
.Business-btn {
  width: 100% !important;
  padding: 16px !important;
  margin: 21px 0;
  margin-top: 21px !important;
  @include breakpoint(1420px) {
    margin-top: 2px !important;
  }
  @include breakpoint(460px) {
    max-width: 250px !important;
    min-width: 250px !important;
  }
}
.Business-btn {
  margin-top: 21px;
  @include breakpoint(1420) {
    margin-top: 21px !important;
  }
}
.vgang-btn-green-box {
  width: 100% !important;
  padding: 16px !important;
  margin: 21px 0;
  margin-top: 38px;
  background: #fff !important;
  color: #008f4a !important;
  @include breakpoint(1420px) {
    margin-top: 18px;
  }
}
.subs_card {
  max-width: 640px;
  overflow: hidden;
  .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-color: $grey_100;
    height: 92px;
    h2 {
      font-size: $font_size_base * 2;
      font-weight: bold;
      @include sm {
        font-size: 18px;
      }
    }
  }
  .card_body {
    .subs_option {
      border-radius: 8px;
      width: 285px;
      border: 1px solid $primary;
      &.active {
        background-color: $green-50;
        border: 1px solid $primary;
      }
    }
    .subs_message {
      color: $primary;
    }
    .text_header_lg {
      font-weight: bold;
      font-size: $font_size_base + 0.8rem;
    }
    .text_header_sm {
      font-weight: 600;
      font-size: $font_size_base + 0.4rem;
    }
    .text_red {
      color: $red-500;
    }
    .text_header {
      font-weight: 600;
      font-size: $font_size_base + 0.6rem;
    }
    .text_header {
      font-weight: 600;
      font-size: $font_size_base + 0.6rem;
    }
    .link_text {
      font-weight: normal;
      color: $primary;
      text-decoration: underline;

      &:hover {
        font-weight: 600;
        cursor: pointer;
      }
    }
    .downgrade_text {
      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
.card_input_wrapper {
  width: 100%;
  border: solid 1px $grey_300;
  padding: 10px;
  border-radius: 8px;
}
.card_exp_cvc_wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  .card_exp_cvc {
    width: 100%;
  }
}
</style>
