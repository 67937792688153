var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"membership-section",class:{
    'justify-content-center':
      _vm.userProfile.payment_method &&
      _vm.currentUserAccountInformation.currentPlan.name == 'Starter',
  }},[_c('div',{staticClass:"plans-section"},[_c('div',{staticClass:"plan-box"},[_vm._m(0),_c('div',{staticClass:"body"},[_c('p',{staticClass:"plan-title"},[_vm._v(" "+_vm._s(_vm.currentUserAccountInformation.currentPlan.name)+" ")]),(_vm.currentUserAccountInformation.currentPlan.support)?_c('ul',{staticClass:"plans-futures"},_vm._l((_vm.currentUserAccountInformation.currentPlan.support.split(
              ','
            )),function(feature){return _c('li',{key:feature,staticClass:"future"},[_vm._v(" "+_vm._s(feature)+" ")])}),0):_vm._e()])])]),_c('div',{staticClass:"payment-section",style:({
      'display:none':
        _vm.userProfile.payment_method &&
        _vm.currentUserAccountInformation.currentPlan.name == 'Starter',
    })},[(!_vm.userProfile.payment_method)?_c('div',{staticClass:"membership-box"},[_vm._m(1),_c('div',{staticClass:"body"},[_c('p',{staticClass:"desc"},[_vm._v(" To process your orders, you need to provide your payment information first. We collect commission only after your customers received their orders. ")]),_c('PButton',{staticClass:"checkout-btn",on:{"click":function($event){return _vm.completeStripeInfo()}}},[_vm._v(" Complete your payment info ")])],1)]):_vm._e(),(_vm.currentUserAccountInformation.currentPlan.name !== 'Starter')?_c('div',{staticClass:"membership-box",staticStyle:{"height":"100% !important"}},[_vm._m(2),_c('div',{staticClass:"body"},[_c('p',{staticClass:"desc"},[_vm._v(" Pausing your subscription? With our Basic plan, you can still keep all your imported products and receive orders. We'll simply collect a 5% commission on any sales made. You can resume your subscription anytime. ")]),_c('PlanSubDowngradeModal',{key:_vm.currentUserAccountInformation.currentPlan.order,attrs:{"isCurrentPlanExpired":true,"isCancelMembershipDefault":true,"plan":_vm.currentUserAccountInformation.currentPlan,"downgradePlanDialog":_vm.downgradePlanDialog},on:{"closeDowngradePlanDialog":function($event){_vm.downgradePlanDialog = false},"OpenDowngradePlan":function($event){_vm.downgradePlanDialog = true}}},[_c('button',{staticClass:"checkout-btn Polaris-Button",attrs:{"type":"button"}},[_c('span',{staticClass:"Polaris-Button__Content"},[_c('span',{staticClass:"Polaris-Button__Text"},[_vm._v(" Cancel subscription ")])])])])],1)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('span',{staticClass:"card-title"},[_vm._v("Your plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('span',{staticClass:"card-title"},[_vm._v("Your payment info")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('span',{staticClass:"card-title"},[_vm._v("Cancel subscription")])])
}]

export { render, staticRenderFns }