<template>
  <button
    :class="{ disabled: load }"
    @click="(load = true), $emit('clicked')"
    :loading="load"
  >
    <div v-if="load" class="spinner-border snipper-border"></div>
    <span v-show="!load"> <slot></slot> </span>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  computed: {
    load: {
      get() {
        return this.loading;
      },

      set(newValue) {},
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
