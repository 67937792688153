<template>
  <div class="input_container">
    <label
      v-if="label"
      :for="id"
      class="input_label"
      :class="hasError ? 'hasError' : ''"
    >
      {{ label }}
    </label>
    <div
      class="input_wrapper"
      :class="{
        hasInlineButton: hasInlineButton,
      }"
    >
      <img
        v-if="icon"
        :src="getInputIcon(icon)"
        alt="icon"
        class="input_icon"
      />
      <span class="input_fixed_value">
        {{ fixedValue }}
      </span>
      <input
        :style="{
          'pointer-events': isSavedProduct ? 'none' : 'auto',
          color: isSavedProduct ? '#8C9196' : 'auto',
          background: isSavedProduct ? '#FAFBFB' : '#435160',
        }"
        v-if="icon"
        :type="type"
        :name="id"
        :id="id"
        :value="value"
        :required="required"
        :placeholder="placeholder ? placeholder : ''"
        class="custom_input"
        :class="{
          hasError: hasError,
          disabled: disabled,
          hasInlineButton: hasInlineButton,
        }"
        @input="updateValue($event.target.value), checkForContent($event)"
        @keyup="$emit('check-input')"
        :maxlength="maxlength ? maxlength : ''"
        :disabled="disabled"
      />
      <input
        :style="{
          'pointer-events': isSavedProduct ? 'none' : 'auto',
          color: isSavedProduct ? '#8C9196' : 'auto',
          background: isSavedProduct ? '#FAFBFB' : '#fff',
        }"
        v-else
        :type="type"
        :name="id"
        :id="id"
        :value="value"
        :required="required"
        :placeholder="placeholder ? placeholder : ''"
        class="custom_input no_icon"
        :class="{
          hasError: hasError,
          disabled: disabled,
          hasInlineButton: hasInlineButton,
        }"
        @input="updateValue($event.target.value), checkForContent($event)"
        @keyup="$emit('check-input')"
        :maxlength="maxlength ? maxlength : ''"
        :disabled="disabled"
      />
      <span
        class="input_hint"
        v-if="hasError"
        :class="hasError ? 'hasError' : ''"
        >{{ errorMessage }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalInput",
  props: [
    "id",
    "label",
    "icon",
    "type",
    "value",
    "placeholder",
    "registerationSteps",
    "fixedValue",
    "maxlength",
    "disabled",
    "hasInlineButton",
    "required",
    "isSavedProduct",
  ],
  data() {
    return {
      hasError: false,
      errorMessage: "",
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    getInputIcon(icon) {
      var InputIcon = require.context("@/assets/img/inputs/", false, /\.png$/);
      return InputIcon("./" + icon + ".png");
    },
    checkForContent(e) {
      if (this.required) {
        let val = e.target.value;
        if (this.id === "phonenumber" && !/^[+]*[0-9]{0,15}$/.test(val)) {
          this.hasError = true;
          this.errorMessage = "Cellphone is not correct";
        } else if (
          this.id === "websiteURL" &&
          !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(
            val
          )
        ) {
          this.hasError = true;
          this.errorMessage = "Website is not correct";
        } else if (
          this.id === "email" &&
          !/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            val
          )
        ) {
          this.hasError = true;
          this.errorMessage = "Email is not correct";
        } else if (e.target.value.length == 0) {
          this.hasError = true;
          this.errorMessage = "Field is required";
        } else {
          this.hasError = false;
          this.errorMessage = "";
        }
      }
    },
  },
};
</script>

<style></style>
