<template>
  <div :style="{ width: maxWidth + ' !important' }">
    <div class="input_container">
      <span class="material-icons search_icon" @click.stop="Search">
        search
      </span>
      <img
        v-show="searchQuery"
        src="@/assets/img/close.png"
        alt="menuclose"
        class="close"
        @click="removeText()"
      />
      <input
        class="input_search"
        type="text"
        name="search"
        id="search"
        :placeholder="placeholder"
        v-model="searchQuery"
        @keyup.enter="Search"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: { type: String },
    maxWidth: { type: String },
    searchText: { type: String },
  },
  data() {
    return {
      searchQuery: this.searchText,
    };
  },
  methods: {
    Search() {
      if (this.searchQuery !== "" && this.searchQuery !== " ") {
        this.$emit("search", this.searchQuery);
      }
    },
    removeText() {
      this.searchQuery = "";
    },
  },
  watch: {
    //FIXME
    // searchQuery: function (val, oldVal) {
    //     if (oldVal?.length && val?.length === 0) {
    //         this.Search();
    //     }
    // },
  },
};
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 25px;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    padding: 5px;
  }
}
.input_container {
  background: #fff;
}
</style>
