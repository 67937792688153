<template>
  <div class="vgang_button_simple" @click="$emit('btn_action', $event)">
    <span v-if="icon" class="material-icons icon"> {{ icon }} </span>
    <button>
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      disablePushToStore: false,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>