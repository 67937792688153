<template>
  <main>
    <div v-if="userType == 'retailer'">
      <retailerNavigation v-if="!$route.path.includes('/sh-retailer')" />
      <v-main :class="{ 'retailer-main-container-setting ': !isShopify }" app>
        <div :class="{ 'mt-10 ': !isShopify }" class="page-wrapper">
          <settingTabsRetailer :currentTab="currentRetailerSettingTab" />
        </div>
      </v-main>
    </div>
  </main>
</template>
<script>
import settingTabsRetailer from "@/components/retailer/settingTabsRetailer.vue";
import retailerNavigation from "@/components/navigation/retailer.vue";
import plansSubcription from "@/components/retailer/settings/plansSubcription.vue";
import { isShopifyEmbedded } from "@shopify/app-bridge/utilities";
// Services
// import CommonData from "@/services/CommonData";
export default {
  components: {
    settingTabsRetailer,
    retailerNavigation,
    plansSubcription,
  },
  data() {
    return {
      isShopify: false,
      userType: "retailer",
      currentRetailerSettingTab: "Membership",
    };
  },
  created() {
    if (isShopifyEmbedded()) {
      this.isShopify = true;
    }
    let data = this.$route.params.data;
    if (data) {
      if (data.currentTab) {
        this.currentRetailerSettingTab = data.currentTab;
      } else {
        this.currentRetailerSettingTab = "plansSubcription";
      }
    }
  },
  props: ["settingTabsRetailer"],
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  // makes the nav tab selector take the full width of the content
  padding: 0;
}
.importlist_banner {
  margin-top: 16px;
  margin-left: 84px;
  margin-bottom: 24px;
  padding-right: 100px;
  width: 100%;
}
.retailer-main-container-setting {
  @media screen and (min-width: 1621px) {
    width: calc(100% - 260px) !important;
    margin-left: auto;
  }
  @media screen and (min-width: 300px) {
    width: calc(100% - 75px);
    margin-left: auto;
  }
}
</style>
