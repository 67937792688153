<template>
  <div>
    <v-dialog
      v-model="downgradePlanDialogCopy"
      :retain-focus="false"
      max-width="640"
      style="z-index: 20001"
      persistent
      eager
      class="plan_sub_upgrade_modal"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-if="isCurrentPlanExpired"
          v-bind="attrs"
          v-on="on"
          @click="OpenDowngradePlan()"
        >
          <slot></slot>
        </button>
        <button
          v-else-if="plan.isFeatured != true"
          class="vgang_btn btn_green Business-btn"
          :class="{
            'Business-btn': plan.name == 'Business',
            'vgang-btn-green-box': plan.name == 'Pro',
          }"
          v-bind="attrs"
          v-on="on"
          @click="OpenDowngradePlan()"
        >
          <slot></slot>
        </button>
        <button
          v-else-if="plan.isFeatured == true"
          :class="{
            'Business-btn': plan.name == 'Business',
            'vgang-btn-green-box': plan.name == 'Pro',
          }"
          class="vgang_btn btn_green tag"
          v-bind="attrs"
          v-on="on"
          @click="OpenDowngradePlan()"
        >
          <slot></slot>
        </button>
      </template>
      <div class="spinner-container" style="" v-if="isLoading">
        <v-progress-circular indeterminate color="green"></v-progress-circular>
      </div>
      <v-card v-if="!isLoading" class="subs_card">
        <div class="card_header d-flex align-center justify-space-between">
          <h2>
            Downgrade to {{ isCurrentPlanExpired ? "Starter" : plan.name }} Plan
          </h2>

          <BaseBtn
            class="vgang_btn btn_green"
            @clicked="!downgradeLoading && closeDowngradePlanDialog()"
            :icon="'close'"
          />
        </div>
        <v-card class="card_body pa-6">
          <div class="cancel-membership-modal-body">
            <div class="text_header">
              Please note that you by<span class="text_red"> downgrading </span>
              your subscription plan, you no longer have access to the
              <span class="text_red"> Business features </span>
              . While we understand your decision, we genuinely want what is
              best for you. Are you sure you want to proceed and downgrade?
            </div>
          </div>

          <div v-if="!plan.isDefault && !isCurrentPlanExpired">
            <StripeElements
              :stripe-key="stripeKey"
              #default="{ elements }"
              :ref="`${plan.name}-elms`"
            >
              <div class="text_header mt-3 mb-3">Card Number</div>
              <div class="card_input_wrapper">
                <StripeElement
                  type="cardNumber"
                  :elements="elements"
                  :ref="`${plan.name}-cardNumber`"
                />
              </div>

              <div class="card_exp_cvc_wrapper">
                <div class="card_exp_cvc">
                  <div class="text_header mt-3 mb-3">Card Expire</div>
                  <div class="card_input_wrapper">
                    <StripeElement
                      type="cardExpiry"
                      :elements="elements"
                      :ref="`${plan.name}-cardExpiry`"
                    />
                  </div>
                </div>
                <div class="card_exp_cvc">
                  <div class="text_header mt-3 mb-3">Card CVC</div>
                  <div class="card_input_wrapper">
                    <StripeElement
                      type="cardCvc"
                      :elements="elements"
                      :ref="`${plan.name}-cardCvc`"
                    />
                  </div>
                </div>
              </div>
            </StripeElements>
          </div>
          <div class="text-center" id="card-element-errors" role="alert"></div>

          <div class="cancel-membership-modal-footer">
            <button
              class="vgang_btn btn_green btn_full_width"
              @click="!downgradeLoading && closeDowngradePlanDialog()"
            >
              Keep my benefits
            </button>
            <div v-if="isCancelMembershipDefault">
              <div
                v-if="!downgradeLoading"
                class="link_text text-center mt-3"
                @click="cancelMembershipDefault"
              >
                Downgrade membership
              </div>
              <div v-else class="text-center mt-3">
                <div class="spinner-border snipper-border"></div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="!downgradeLoading"
                class="link_text text-center mt-3"
                @click="cancelMembership"
              >
                Downgrade membership
              </div>
              <div v-else class="text-center mt-3">
                <div class="spinner-border snipper-border"></div>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// components
import SimpleBtn from "@/components/bases/SimpleBtn.vue";
import BaseBtn from "@/components/bases/BaseBtn.vue";
import LoadingBtn from "@/components/bases/Loading.vue";
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";
// Services
import SettingService from "@/services/SettingService";

export default {
  name: "PlanSubscriptionDowngrade",
  props: {
    plan: {
      type: Object,
      required: true,
    },
    downgradePlanDialog: {
      type: Boolean,
      required: true,
    },
    isCancelMembershipDefault: {
      type: Boolean,
      required: false,
    },
    isCurrentPlanExpired: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    SimpleBtn,
    BaseBtn,
    LoadingBtn,
    StripeElements,
    StripeElement,
  },

  data() {
    return {
      isLoading: true,
      downgradeLoading: false,
      stripeKey: process.env.VUE_APP_STRIPE_APP_KEY || "", // test key, don't hardcode
      planPicked: "yearly",
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: "",
        },
        // https://stripe.com/docs/stripe.js#element-options
      },
    };
  },

  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {
    ...mapGetters({
      plans: "retailer/listOfPlansSubscription",
      CurrentUserInformation: "retailer/currentUserInformation",
      currentPlanSubscription: "retailer/currentPlanSubscription",
    }),
    downgradePlanDialogCopy() {
      return this.downgradePlanDialog;
    },
  },
  methods: {
    ...mapActions([
      "retailer/getPlansSubscription",
      "alert/clear",
      "alert/newAlert",
    ]),

    toUpgrade(planOrder) {
      const SubedPlan = this.plans.find((plan) => plan.userSub);
      const found = this.plans.find(
        (plan) => !plan.userSub && SubedPlan.order < planOrder
      );
      if (found) return true;
      else return false;
    },

    OpenDowngradePlan() {
      this.$emit("OpenDowngradePlan", true);
    },
    closeDowngradePlanDialog() {
      this.$emit("closeDowngradePlanDialog", false);
    },

    cancelMembershipDefault() {
      SettingService.cancelMembership()
        .then(async (response) => {
          console.log(response);
          setTimeout(async () => {
            await this["retailer/getPlansSubscription"]();
            this.downgradeLoading = false;
            this.$emit("closeDowngradePlanDialog", false);
            this["alert/newAlert"]({
              type: "success",
              message: "Plan downgraded successfully",
            });
            setTimeout(async () => {
              this["alert/clear"]();
            }, 3000);
          }, 2500);
        })
        .catch((e) => {
          this.downgradeLoading = false;
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this["alert/clear"]();
          }, 3000);
        });
    },

    cancelMembership() {
      this.downgradeLoading = true;
      if (this.plan.isDefault) {
        SettingService.cancelMembership()
          .then(async (response) => {
            console.log(response);
            setTimeout(async () => {
              await this["retailer/getPlansSubscription"]();
              this.downgradeLoading = false;
              this.$emit("closeDowngradePlanDialog", false);
              this["alert/newAlert"]({
                type: "success",
                message: "Plan downgraded successfully",
              });
              setTimeout(async () => {
                this["alert/clear"]();
              }, 3000);
            }, 2500);
          })
          .catch((e) => {
            this.downgradeLoading = false;
            this["alert/newAlert"]({
              type: "error",
              message: e.data.message,
            });
            setTimeout(async () => {
              this["alert/clear"]();
            }, 3000);
          });
      } else {
        const purchaseID =
          this.currentPlanSubscription.subscriptionType === 0
            ? this.plan.monthlyPriceID
            : this.plan.yearlyPriceID;
        // ref in template
        const groupComponent = this.$refs[`${this.plan.name}-elms`];
        const cardComponent = this.$refs[`${this.plan.name}-cardNumber`];
        const cardExpiryComponent = this.$refs[`${this.plan.name}-cardExpiry`];
        const cardCvcComponent = this.$refs[`${this.plan.name}-cardCvc`];
        // Get stripe element
        const cardElement = cardComponent.stripeElement;
        const cardExpiryElement = cardComponent.stripeElement;
        const cardCvcElement = cardComponent.stripeElement;
        // Access instance methods, e.g. createToken()
        groupComponent.instance.createToken(cardElement).then((result) => {
          if (result.error) {
            this["alert/newAlert"]({
              type: "error",
              message: result.error.message,
            });
            setTimeout(async () => {
              this["alert/clear"]();
            }, 3000);
            let displayError = document.getElementById("card-element-errors");
            displayError.textContent = result.error.message;
            this.downgradeLoading = false;
          } else {
            // handle the token
            SettingService.getClientSecret(purchaseID)
              .then((response) => {
                const clientSecret = response.data.data.clientSecret;
                groupComponent.instance
                  .confirmCardPayment(clientSecret, {
                    payment_method: {
                      card: cardElement,
                      billing_details: {
                        name: this.CurrentUserInformation.email,
                      },
                    },
                  })
                  .then(async (result) => {
                    if (result.error) {
                      this["alert/newAlert"]({
                        type: "error",
                        message: result.error.message,
                      });
                      setTimeout(async () => {
                        this["alert/clear"]();
                      }, 3000);
                      let displayError = document.getElementById(
                        "card-element-errors"
                      );
                      displayError.textContent = result.error.message;
                      this.downgradeLoading = false;
                    } else {
                      setTimeout(async () => {
                        await this["retailer/getPlansSubscription"]();
                        this.downgradeLoading = false;
                        this.$emit("closeDowngradePlanDialog", false);
                        this["alert/newAlert"]({
                          type: "success",
                          message: "Plan downgraded successfully",
                        });
                        setTimeout(async () => {
                          this["alert/clear"]();
                        }, 3000);
                      }, 2500);
                    }
                  })
                  .catch((e) => {
                    console.log("error1", e);
                    this.downgradeLoading = false;
                  });
              })
              .catch((e) => {
                console.log("error2", e);
                this.downgradeLoading = false;
              });
          }
        });
      }
    },
  },
  mounted() {
    const stripeScriptSrc = "https://js.stripe.com/v3/";

    this.isLoading = true;
    const script = document.createElement("script");
    script.src = stripeScriptSrc;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => {
      this.isLoading = false;
      // The Stripe script has loaded, you can now use Stripe functionality
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}
#cancel-membership-modal {
  .cancel-membership-modal-body {
    padding: 24px;
  }
  .cancel-membership-modal-footer {
    display: flex;
    justify-content: flex-end;
    width: auto;
    button {
      width: 90px;
      margin-right: 12px;
    }
  }
}
.Business-btn,
.vgang-btn-green-box {
  min-width: 100% !important;
  padding: 16px !important;
  margin: 21px 0;
  margin-top: 22px;
}
.Business-btn {
  margin-top: 19px;
  @include breakpoint(1300px) {
    margin-top: 40px;
  }
}

.vgang-btn-green-box {
  width: 100% !important;
  padding: 16px !important;
  margin: 21px 0;
  margin-top: 38px;
  background: #fff !important;
  color: #008f4a !important;
}
#card-element-errors {
  color: $red-500;
  padding: 24px 0;
}
.subs_card {
  max-width: 640px;
  overflow: hidden;
  .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-color: $grey_100;
    height: 92px;
    h2 {
      font-size: $font_size_base * 2;
      font-weight: bold;
      @include sm {
        font-size: 18px;
      }
    }
  }
  .card_body {
    .subs_option {
      border-radius: 8px;
      width: 285px;
      border: 1px solid $primary;
      &.active {
        background-color: $green-50;
        border: 1px solid $primary;
      }
    }
    .subs_message {
      color: $primary;
    }
    .text_header_lg {
      font-weight: bold;
      font-size: $font_size_base + 0.8rem;
    }
    .text_header_sm {
      font-weight: 600;
      font-size: $font_size_base + 0.4rem;
    }
    .text_red {
      color: $red-500;
    }
    .text_header {
      font-weight: 600;
      font-size: $font_size_base + 0.6rem;
    }
    .text_header {
      font-weight: 600;
      font-size: $font_size_base + 0.6rem;
    }
    .link_text {
      font-weight: normal;
      color: $primary;
      text-decoration: underline;

      &:hover {
        font-weight: 600;
        cursor: pointer;
      }
    }
    .downgrade_text {
      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
.payment-simple {
  width: 100%;
  border: solid 1px $grey_300;
  padding: 10px;
  border-radius: 8px;
}
.card_input_wrapper {
  width: 100%;
  border: solid 1px $grey_300;
  padding: 10px;
  border-radius: 8px;
}
.card_exp_cvc_wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  .card_exp_cvc {
    width: 100%;
  }
}
</style>
