<template>
  <main class="px-6 mb-6">
    <div class="setting-tabs-wrapper">
      <ul>
        <!-- <li
          class="each-tab"
          :class="{ active: currentTab == 'plansSubcription' }"
          @click="currentTab = 'plansSubcription'"
        >
          Plans
        </li> -->
        <li
          class="each-tab"
          :class="{ active: currentTab == 'Membership' }"
          @click="currentTab = 'Membership'"
        >
          Membership
        </li>
        <li
          class="each-tab"
          :class="{ active: currentTab == 'accountRetailer' }"
          @click="currentTab = 'accountRetailer'"
        >
          Account
        </li>

        <!-- <li class="each-tab"
          :class="{ active: currentTab == 'securityRetailer' }"
          @click="currentTab = 'securityRetailer'"
        >Security</li> -->
      </ul>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12" style="padding: 0">
          <div class="tab-content mt-10">
            <component :is="currentTab"></component>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// Components

import plansSubcription from "@/components/retailer/settings/plansSubcription.vue";
import accountRetailer from "@/components/retailer/settings/accountRetailer.vue";
import securityRetailer from "@/components/retailer/settings/securityRetailer.vue";
import setting from "@/views/retailer/Setting.vue";
import Membership from "@/components/retailer/settings/Membership.vue";
// Services
import SettingService from "@/services/SettingService";
import RegisterationSetup from "@/services/RegisterationSetup";
// import CommonData from "@/services/CommonData";
import { removeVariable } from "@/utils/conditionalStoreVariables";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    plansSubcription,
    accountRetailer,
    securityRetailer,
    Membership,
    setting,
  },
  data() {
    return {
      addingCardErrorMessage: "",
      loading: false,
      lineItems: [
        {
          price: "some-price-id", // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: "your-success-url",
      cancelURL: "your-cancel-url",
      accountInfo: {
        currency: "",
        currentPlan: "",
        email: "",
        nextPlan: "",
        phoneNumber: "",
        storeName: "",
        storeUrl: "",
      },
      password: {
        old: "",
        new: "",
        repeat: "",
      },
      changePasswordError: "",
      hasChangePasswordError: false,
      changePasswordSuccess: false,
      cardInfo: {
        number: "",
        expMonth: "",
        expYear: "",
        cvc: "",
      },
      max: 2,
      cards: "",
      monthlyPriceID: "",
      yearlyPriceID: "",
      showAddCardSuccess: false,
      showAddCardError: false,
      showSubscribeModal: false,
      userEmail: "",
      shopifyURL: "",
      purchaseID: "",
      cardEl: "",
      stripe: "",
      showCancelMembershipModal: false,
      shopInstalled: false,
    };
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  mounted() {
    this["retailer/getPlansSubscription"]();
    this["retailer/getCurrentUserInformation"]();
    this.getCards();
  },
  computed: {
    ...mapGetters({
      CurrentUserInformation: "retailer/currentUserInformation",
    }),
    IntegrationButtonIsDisabled() {
      if (this.shopifyURL.length > 0) return false;
      else return true;
    },
  },
  props: {
    currentTab: {
      default: "Membership",
    },
  },
  methods: {
    ...mapActions([
      "retailer/getPlansSubscription",
      "retailer/getCurrentUserInformation",
    ]),
    connectShop() {
      const params = {
        storeUrl: this.shopifyURL,
        platformName: "shopifyfeeder",
      };
      RegisterationSetup.installLinkRetailer(params)
        .then((response) => {
          this.redirectURL = response.data.redirectUrl;
          window.location.replace(this.redirectURL);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeTab(tab) {
      this.showSubscribeModal = false;
      this.currentTab = tab;
    },
    initStripe() {
      var stripe = window.Stripe(
        "pk_test_51JaloOLQ4UrrNguewf5E2qHHffheBerSvcC0twHrMLsDdEikTKVFmbubYplFuFh00j1cnO6viyZ8GnYRrtaTKknB00zr27Em2D"
      );
      this.stripe = stripe;
      let elements = stripe.elements();
      var cardElement = elements.create("card");

      cardElement.mount("#card-element");
      cardElement.on("change", function (event) {
        displayError(event);
      });
      this.cardEl = cardElement;
      function displayError(event) {
        // changeLoadingStatePrices(false);
        let displayError = document.getElementById("card-element-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      }
    },
    changePassword() {
      const params = {
        oldPassword: this.password.old,
        newPassword: this.password.new,
        repeatNewPassword: this.password.repeat,
      };
      SettingService.changePassword(params)
        .then((response) => {
          this.hasChangePasswordError = false;
          this.changePasswordSuccess = true;
        })
        .catch((e) => {
          this.changePasswordError = e.response.data.message;
          this.changePasswordSuccess = false;
          this.hasChangePasswordError = true;
        });
    },
    getPlans() {
      SettingService.getPlans()
        .then((response) => {
          this.plans = response.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCards() {
      SettingService.getCards()
        .then((response) => {
          this.cards = response.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteCard(id) {
      SettingService.deleteCard(id)
        .then((response) => {
          console.log(response);
          this.getCards();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setDefaultCard(id) {
      SettingService.setDefault(id)
        .then((response) => {
          console.log(response);
          this.getCards();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    cancelMembership() {
      SettingService.cancelMembership()
        .then((response) => {
          console.log(response);
          this.showCancelMembershipModal = false;
          this.getPlans();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addCard() {
      const params = {
        number: this.cardInfo.number.replace(/\s/g, ""),
        expMonth: this.cardInfo.expMonth,
        expYear: 20 + this.cardInfo.expYear,
        cvc: this.cardInfo.cvc,
      };

      SettingService.addCard(params)
        .then((response) => {
          console.log(response);
          this.showAddCardError = false;
          this.showAddCardSuccess = true;
          this.cardInfo.number = "";
          this.cardInfo.expMonth = "";
          this.cardInfo.expYear = "";
          this.cardInfo.cvc = "";
          this.getCards();
        })
        .catch((e) => {
          this.showAddCardSuccess = false;
          this.showAddCardError = true;
          this.addingCardErrorMessage = e.response.data.message;
          console.log(e);
        });
    },
    handleLogout() {
      removeVariable("role");
      this.message = "";
      this.submitted = true;
      this.$store.dispatch("auth/logout").then(
        (data) => {
          this.$router.go({ name: "LoginSupplier" });
          this.message = data.message;
          this.successful = true;
          removeVariable("user");
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    handlePaymentModal(monthlyPriceID, yearlyPriceID) {
      this.showSubscribeModal = true;

      this.purchaseID = monthlyPriceID;
    },
    confirmPurchase() {
      SettingService.getClientSecret(this.purchaseID)
        .then((response) => {
          console.log(response);
          this.clientSecret = response.data.data.clientSecret;
          this.stripe
            .confirmCardPayment(this.clientSecret, {
              payment_method: {
                card: this.cardEl,
                billing_details: {
                  name: this.CurrentUserInformation.email,
                },
              },
            })
            .then((result) => {
              if (result.error) {
                alert(result.error.message);
              } else {
                alert("Success");
                console.log(result);
              }
            });
        })
        .catch((e) => {
          console.log(e);
        });
      const btn = document.querySelector("#submit-payment-btn");
      btn.addEventListener("click", async (e) => {
        e.preventDefault();
        // Create payment method and confirm payment intent.
      });
    },
  },
};
</script>

<style lang="scss">
.setting-tabs-wrapper {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #e1e3e5;
  padding-bottom: 0;
  margin-bottom: 0;
  position: relative;
  flex-wrap: wrap;
  @include md {
    overflow-x: auto;
  }

  ul {
    list-style: none;
    display: flex;
    column-gap: 14px;
    width: 400px;
    padding-top: 24px;
    padding-bottom: 0;
    padding-left: 0;
    margin: 0;
    @media (max-width: 500px) {
      width: 300px;
    }
    .each-tab {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #435160;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 21px;
      padding-bottom: 15px;

      .icon {
        font-size: 14px;
        margin-right: 12px;
        font-weight: 600;
      }
      &.active {
        color: #111418;
        font-weight: bold;
        border-bottom: 4px solid #008060;
        .icon {
          font-weight: bold;
        }
      }
    }
  }
}
.green-bg {
  background-color: #008f4a !important;
  margin-top: 0 !important;
  @include lg {
    margin-top: 34px !important;
  }
  .plan-title,
  .price,
  .desc,
  .feature {
    color: #fefefe !important;
  }
  .icon {
    color: #fff !important;
  }

  .custom-button {
    background-color: #fff;
    color: #008f4a !important;
  }
  .features .feature img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
      hue-rotate(266deg) brightness(106%) contrast(105%) !important;
  }
  .discount {
    color: #fefefe !important;
  }
}
.plans-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding-bottom: 58px;
  @include xl {
    row-gap: 34px;
  }
  @include lg {
    row-gap: 34px;
  }

  .each-plan {
    border-radius: 10px;
    background-color: #fff;
    padding: 18px;
    margin: 12px 18px;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 420px;
    min-width: 270px;
    height: 100%;
    position: relative;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),
      0px 0px 5px 0px rgba(0, 0, 0, 0.05);

    @include breakpoint(1420px) {
      width: 380px;
    }
    @include breakpoint(1300px) {
      width: 330px;
    }

    @include lg {
      margin-top: 0px !important;
      min-height: 690px;
      width: 370px;
    }
    @include sm {
      width: 80vw;
    }

    .button-wrapper {
      width: 100%;
      text-align: center;

      &.tag {
        color: #ffffff;
      }
    }
    .plan-title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #344054;
      &.tag {
        color: #ffffff;
      }
    }
    .discount {
      color: #008f4a;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      &.tag {
        color: #ffffff;
      }
    }
    .off {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 8px;
      width: 264px;
      height: 20px;
      background: #cde9ce;
      border-radius: 4px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin-right: 10px;
      color: #05603a;
      margin-top: 16px;
      &.tag {
        color: #05603a;
        margin-right: 11px;
      }
    }
    .price-section {
      margin-bottom: 21px;
      margin-top: 21px;
      .price {
        font-size: 32px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #344054;
        margin-bottom: 20px;
        &.tag {
          color: #ffffff;
        }
      }
      .price-detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: -124px;
        &.tag {
          color: #ffffff;
        }

        span {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: right;
          margin-top: 16px;
          color: #000000;
          &.tag {
            color: #ffffff;
          }
          &.static-text {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #667085;
            margin-left: -140px;

            &.tag {
              color: #ffffff;
            }
          }
        }
        .static-textbill {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #667085;
          width: 139px;
        }
      }
    }
    .desc {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-family: "Open Sans";
      font-style: normal;
      line-height: 20px;
      letter-spacing: normal;
      color: #344054;
      margin-bottom: 24px;
      &.tag {
        color: #ffffff;
      }
    }
    .features {
      .each {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        .icon {
          color: #008f4a;
          margin-right: 12px;
          font-size: 20px;
          &.tag {
            color: #ffffff;
          }
        }
        .feature {
          font-size: 14px !important;
          font-weight: 700;
          line-height: 24px;
          color: #667085;
          &.tag {
            color: #ffffff;
          }
        }
      }
      &.tag {
        color: #ffffff;
      }
    }
    .deactive {
      .feature {
        color: #98a2b3 !important;
        text-decoration: line-through;
      }
      .icon {
        color: #98a2b3 !important;
      }
    }
  }
  &featured {
    .each-plan-feature {
      border-radius: 10px;

      padding: 13px;
      margin: 12px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 290px;
      min-width: 270px;
      min-height: 650px;
      position: relative;
      .button-wrapper {
        width: 100%;
        text-align: center;
        left: 0;
        margin-top: auto;
      }
      .price-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .price {
          font-size: 32px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: normal;
          color: #a7c0b4;
          margin-bottom: 20px;
        }
        .price-detail {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: -120px;

          span {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: right;
            margin-top: 16px;
            color: #000000;
            &.static-text {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              color: #667085;
              margin-left: -125px;
            }
          }
        }
      }
      .desc-featured {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-family: "Open Sans";
        font-style: normal;
        line-height: 20px;
        letter-spacing: normal;
        color: #a80f0f;
        margin-bottom: 24px;
      }
      .features {
        .each {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 12px;
          .icon {
            color: #008f4a;
            margin-right: 12px;
            font-size: 20px;
          }
          .feature {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #000000;
          }
        }
      }
    }
  }
}

.tab-content {
  padding-top: 24px;
  min-height: 83vh;
  border-radius: 14px;
  .edit-profile-box {
    border-radius: 10px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 0px 4px rgba(16, 24, 40, 0.06);
    background-color: #fff;
    height: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f3f5f7;
      padding: 24px;
      margin-bottom: 12px;
      .card-title {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #435160;
      }
    }
    .body {
      .body-inputs-setting {
        border-bottom: 1px solid #f3f5f7;
        padding: 24px;
      }
      .account-info {
        border-bottom: 1px solid #f3f5f7;
        padding: 24px;
        .key,
        .value {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #435160;
        }
        .value {
          font-weight: 600;
        }
        .plan-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 24px;
        }
      }
      .logout-container {
        padding: 24px;
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #435160;
          cursor: pointer;
        }
        .icon {
          margin-right: 12px;
          vertical-align: middle;
        }
      }
    }
  }
  .inline-inputs {
    display: flex;
    justify-content: space-between;
  }
}

.connect-shopify-box {
  border-radius: 10px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 0px 4px rgba(16, 24, 40, 0.06);
  background-color: #fff;
  height: 100%;

  .header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f3f5f7;
    padding: 24px;
    margin-bottom: 12px;
    .card-title {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #435160;
    }
    .card-subtitle {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #435160;
    }
  }
  .body {
    padding-left: 24px;
    padding-right: 24px;
    .shopify-url-input-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .button {
        margin-left: 12px;
        width: unset !important;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.changepassword-box {
  border-radius: 10px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 0px 4px rgba(16, 24, 40, 0.06);
  background-color: #fff;
  width: 600px;
  display: block;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 280px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f3f5f7;
    padding: 24px;
    margin-bottom: 12px;
    .card-title {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #435160;
      margin: 0;
    }
  }
  .body {
    padding: 24px;
    padding-top: 0;
  }
}
.paymentmethod-box {
  border-radius: 10px;
  box-shadow: 0 0 20px 5px rgba(29, 41, 54, 0.1);
  background-color: #fff;
  width: 624px;
  display: block;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f3f5f7;
    padding: 24px;
    margin-bottom: 12px;
    .card-title {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #435160;
      margin: 0;
    }
  }
  .body {
    padding: 24px;
    padding-top: 0;
    .add-card-wrapper {
      display: flex;
      position: relative;
      input {
        padding: 10px 12px;
        border-radius: 10px;
        box-shadow: 0 0 15px 3px rgba(29, 41, 54, 0.07),
          0 0 5px 0 rgba(29, 41, 54, 0.07);
        background-color: #fff;
        &::placeholder {
          font-size: 12px;
        }
        &:focus {
          outline: none;
        }
      }
      .card-icon {
        position: absolute;
        left: 350px;
        top: 10px;
      }
      .card-number {
        width: 390px;
        margin-right: 12px;
        font-size: 14px;
        &::placeholder {
          font-size: 15px;
          letter-spacing: 2px;
        }
      }
      .card-month-year {
        border-radius: 10px;
        box-shadow: 0 0 15px 3px rgba(29, 41, 54, 0.07),
          0 0 5px 0 rgba(29, 41, 54, 0.07);
        background-color: #fff;
        width: 80px;
        margin-right: 12px;
        display: flex;
        .card-expYear,
        .card-expMonth {
          box-shadow: none;
          width: 30px;
          padding: 0;
          font-size: 14px;
        }
        .card-expYear {
          padding-right: 5px;
        }
        .card-expMonth {
          padding-left: 5px;
        }
        span {
          color: gray;
          padding-top: 10px;
          padding-right: 5px;
          padding-left: 5px;
        }
      }
      .card-cvc {
        width: 80px;
        font-size: 14px;
      }
    }
    .added-cards {
      margin-top: 24px;
      .each {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #435160;
        margin-bottom: 24px;
        justify-content: space-between;
        .card-logo {
          width: 30px;
          margin-right: 24px;
        }
        .card-actions {
          display: flex;
          align-items: center;
        }
        .set-default {
          margin-right: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;
          border-right: solid 1px #f3f5f7;
          padding-right: 12px;
          img {
            margin-right: 12px;
          }
          &.is-default {
            color: #008f4a;
            display: flex;
            align-items: center;
          }
        }
        .delete-card {
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            margin-right: 12px;
          }
        }
      }
    }
  }
}
.modal-content {
  padding: 24px;
}
.your-plan {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435160;
  padding: 18px;
  border-radius: 8px;
  background: #e4e7ec;
  display: block;

  margin: 21px auto;
  margin-top: 64px;
}
.cancel-plan {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435160;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;

  display: block;
  width: 200px;
  margin: 0 auto;
  cursor: pointer;
}
.subscription-modal {
  display: block;
  margin: 0 auto;
  box-shadow: 0 0 20px 5px rgba(29, 41, 54, 0.1);
  border-radius: 10px;
  width: 600px;
  opacity: 0;
  pointer-events: none;
  .header {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 24px;
    .card-title {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #435160;
    }
  }
  .body {
    padding-right: 48px;
    padding-left: 48px;
    input {
      border: 1px solid red;
    }
  }
  &.show {
    pointer-events: unset;
    opacity: 1;
  }
}

#cancel-membership-modal {
  .cancel-membership-modal-body {
    padding: 24px;
  }
  .cancel-membership-modal-footer {
    display: flex;
    justify-content: flex-end;
    width: auto;
    button {
      width: 90px;
      margin-right: 12px;
    }
  }
}
</style>
