<template>
  <div>
    <div class="col-sm-12">
      <div class="changepassword-box">
        <div class="header">
          <span class="card-title">Change Password</span>
        </div>
        <div class="body">
          <span class="form-error" v-if="hasChangePasswordError">{{
            changePasswordError
          }}</span>
          <span class="form-success" v-if="changePasswordSuccess"
            >Password changed successfully!</span
          >
          <div class="form">
            <CustomPasswordInput
              type="password"
              id="password"
              label="Your Old Password"
              v-model="password.old"
            />
            <CustomPasswordInput
              type="password"
              id="password"
              label="Your New Password"
              v-model="password.new"
            />
            <CustomPasswordInput
              type="password"
              id="repeat-password"
              label="Repeat Password"
              v-model="password.repeat"
            />
          </div>
          <div class="d-flex justify-content-center">
            <button
              class="vgang-button text-center outlined"
              @click="changePassword"
            >
              Change password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
// Services
import SettingService from "@/services/SettingService";
export default {
  components: {
    CustomPasswordInput,
  },
  data() {
    return {
      password: {
        old: "",
        new: "",
        repeat: "",
      },
      hasChangePasswordError: false,
      changePasswordError: "",
      changePasswordSuccess: false,
    };
  },
  methods: {
    changePassword() {
      if (!this.password.old || !this.password.new) {
        this.changePasswordError =
          "Your New Password or Repeat Password is empty!";
        this.changePasswordSuccess = false;
        this.hasChangePasswordError = true;
      } else {
        const params = {
          oldPassword: this.password.old,
          newPassword: this.password.new,
          repeatNewPassword: this.password.repeat,
        };
        SettingService.changePassword(params)
          .then((response) => {
            console.log(response);
            this.hasChangePasswordError = false;
            this.changePasswordSuccess = true;
          })
          .catch((e) => {
            if (e.data.validations) {
              this.changePasswordError =
                "Your New Password or repeat password is empty or doesn't match!";
              this.changePasswordSuccess = false;
              this.hasChangePasswordError = true;
            } else {
              this.changePasswordError = e.data.message;
              this.changePasswordSuccess = false;
              this.hasChangePasswordError = true;
            }
          });
      }
    },
  },
};
</script>

<style></style>
