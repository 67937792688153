<template>
  <div>
    <div v-if="plans.current_plan_expired" class="commission-base-section mb-6">
      <div class="commission-container">
        <h4 class="title">Your Plan: {{ plans.current_plan_expired.name }}</h4>

        <!-- <h4 v-if="plans.current_plan_expired.Type == 0" class="title">
          Your Plan Cost: {{ plans.current_plan_expired.monthlyPrice }}/mo
        </h4>
        <h4 v-if="plans.current_plan_expired.Type == 1" class="title">
          Your Plan Cost: {{ plans.current_plan_expired.yearlyPrice }}/yearly
        </h4> -->
        <p class="desc">
          We're updating our pricing plans, but you can stay on your current
          plan with unlimited access to import products and checkout for the
          same price you're paying now.
        </p>
        <PlanSubDowngradeModal
          :isCurrentPlanExpired="true"
          :isCancelMembershipDefault="true"
          :plan="plans.current_plan_expired"
          :key="plans.current_plan_expired.order"
          :downgradePlanDialog="downgradePlanDialog"
          @closeDowngradePlanDialog="downgradePlanDialog = false"
          @OpenDowngradePlan="downgradePlanDialog = true"
        >
          <span class="Upgrade-link">Cancel subscription</span>
        </PlanSubDowngradeModal>
      </div>
    </div>
    <div
      class="toggle-wrapper d-flex justify-content-center align-items-center"
    >
      <span
        :class="priceChangeValue ? ' toggle-label green-text' : 'toggle-label'"
        >Pay annually
      </span>
      <label class="switch">
        <input type="checkbox" v-model="priceChangeValue" />
        <span class="slider round"></span>
      </label>

      <span
        :class="!priceChangeValue ? ' toggle-label green-text' : 'toggle-label'"
        >Pay monthly
      </span>
    </div>

    <div class="plans-wrapper" v-if="plans.data?.length">
      <div v-for="plan in plans.data" :key="plan.name">
        <div
          class="each-plan"
          :class="plan.name == 'Pro' ? 'green-bg' : ''"
          v-if="plan.isFeatured === false"
        >
          <PlanCard :priceChangeValue="priceChangeValue" :plan="plan" />
        </div>
        <div
          class="each-plan tag"
          :class="plan.name == 'Pro' ? 'green-bg' : ''"
          v-if="plan.isFeatured === true"
        >
          <PlanCard :priceChangeValue="priceChangeValue" :plan="plan" />
        </div>
      </div>
    </div>

    <div v-else class="plans-wrapper">
      <div class="empty-space">
        <h2 class="_title" style="text-align: center">
          Please contact the support team and help us repport this issue.
        </h2>

        <img
          src="@/assets/img/vgang-pale-bg.png"
          alt="vgang"
          class="_bg"
          style="
            top: 500px;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            margin: auto;
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// components
import PlanCard from "@/components/retailer/settings/planCard.vue";
import PlanSubDowngradeModal from "@/components/retailer/modals/planSubDowngradeModal.vue";
export default {
  name: "PlansSubscription",
  components: {
    PlanCard,
    PlanSubDowngradeModal,
  },
  data() {
    return {
      currentTab: "plans",
      downgradePlanDialog: true,
      addingCardErrorMessage: "",
      showCancelMembershipModal: false,
      showSubscribeModal: false,
      upgradePlanDialog: false,
      downgradePlanDialog: false,
      priceChangeValue: true,

      stripeKey:
        "pk_test_51JaloOLQ4UrrNguewf5E2qHHffheBerSvcC0twHrMLsDdEikTKVFmbubYplFuFh00j1cnO6viyZ8GnYRrtaTKknB00zr27Em2D", // test key, don't hardcode
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: "",
        },
        // https://stripe.com/docs/stripe.js#element-options
      },
    };
  },

  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {
    ...mapGetters({
      plans: "retailer/listOfPlansSubscription",
    }),
  },
  methods: {
    initStripe() {
      var stripe = window.Stripe(
        "pk_test_51JaloOLQ4UrrNguewf5E2qHHffheBerSvcC0twHrMLsDdEikTKVFmbubYplFuFh00j1cnO6viyZ8GnYRrtaTKknB00zr27Em2D"
      );
      this.stripe = stripe;
      let elements = stripe.elements();
      var cardElement = elements.create("card");

      cardElement.mount("#card-element");
      cardElement.on("change", function (event) {
        displayError(event);
      });
      this.cardEl = cardElement;
      function displayError(event) {
        // changeLoadingStatePrices(false);
        let displayError = document.getElementById("card-element-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      }
    },

    handlePaymentModal(monthlyPriceID, yearlyPriceID) {
      this.showSubscribeModal = true;

      this.purchaseID = monthlyPriceID;
      // this.yearlyPriceID = yearlyPriceID;
    },
    confirmPurchase(monthlyPriceID, yearlyPriceID) {
      const purchaseID =
        this.planPicked === "monthly" ? monthlyPriceID : yearlyPriceID;

      // todo this.purchaseID is the monthily price id or the yearly price id available in the plans array
      SettingService.getClientSecret(purchaseID)
        .then((response) => {
          console.log(response);
          this.clientSecret = response.data.data.clientSecret;
          this.stripe
            .confirmCardPayment(this.clientSecret, {
              payment_method: {
                card: this.cardEl,
                billing_details: {
                  name: this.userEmail,
                },
              },
            })
            .then((result) => {
              if (result.error) {
                alert(result.error.message);
              } else {
                alert("Success");
                console.log(result);
              }
            });
        })
        .catch((e) => {
          console.log(e);
        });
      const btn = document.querySelector("#submit-payment-btn");
      btn.addEventListener("click", async (e) => {
        e.preventDefault();
      });
    },
    cancelMembership() {
      SettingService.cancelMembership()
        .then((response) => {
          console.log(response);
          this.showCancelMembershipModal = false;
          this.getPlans();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    toUpgrade(planOrder) {
      const SubedPlan = this.plans.data?.find((plan) => plan.userSub);
      const found = this.plans.data?.find(
        (plan) => !plan.userSub && SubedPlan.order < planOrder
      );
      if (found) return true;
      else return false;
    },
    OpenUpgradePlan() {
      this.showCancelMembershipModal = !this.showCancelMembershipModal;
    },
    pay(planName, monthlyPriceID, yearlyPriceID) {
      const purchaseID =
        this.planPicked === "monthly" ? monthlyPriceID : yearlyPriceID;

      // ref in template
      const groupComponent = this.$refs[`${planName}-elms`];
      const cardComponent = this.$refs[`${planName}-card`];

      // Get stripe element
      const cardElement = cardComponent[0].stripeElement;

      // Access instance methods, e.g. createToken()
      groupComponent[0].instance.createToken(cardElement).then((result) => {
        if (result.error) {
          let displayError = document.getElementById("card-element-errors");
          displayError.textContent = result.error.message;
        } else {
          // handle the token
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";
.commission-base-section {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),
    0px 0px 5px 0px rgba(0, 0, 0, 0.05);

  .commission-container {
    .title {
      color: #202223;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .desc {
      color: #202223;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .Upgrade-link {
      margin-left: 14px;
      cursor: pointer;
      color: #2c6ecb;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
      background: unset !important;
    }
  }
}
.toggle-wrapper {
  margin: 0;
  margin-bottom: 95px;
  text-align: center;
  @include lg {
    margin: 34px 0;
    margin-bottom: 95px;
  }
  @include md {
    margin: 34px 0;
    margin-bottom: 55px;
  }
  .toggle-label {
    font-weight: 600;
    font-size: 16px;
    margin: 0 8px;
    @include lg {
      font-size: 14px;
    }
  }
}
#cancel-membership-modal {
  .cancel-membership-modal-body {
    padding: 24px;
  }
  .cancel-membership-modal-footer {
    display: flex;
    justify-content: flex-end;
    width: auto;
    button {
      width: 90px;
      margin-right: 12px;
    }
  }
}
.subs_card {
  max-width: 640px;
  overflow: hidden;
  .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-color: $grey_100;
    height: 92px;
    h2 {
      font-size: $font_size_base * 2;
      font-weight: bold;
    }
  }
  .card_body {
    .subs_message {
      color: $primary;
    }
    .subs_plan_name {
      font-weight: bold;
      font-size: $font_size_base + 0.8rem;
    }
    .text_header {
      font-weight: 600;
      font-size: $font_size_base + 0.6rem;
    }
    .text_header {
      font-weight: 600;
      font-size: $font_size_base + 0.6rem;
    }
    .plan_change {
      font-weight: 600;
      font-size: $font_size_base + 0.6rem;
    }
  }
}
.payment-simple {
  width: 100%;
}

.empty-space {
  position: relative;
  padding-left: 60px;
  ._title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #435160;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  ._desc {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #435160;
    margin-bottom: 30px;
  }
  ._bg {
    position: absolute;
    top: 100px;
    right: 100px;
  }
}
.plans {
  display: grid;
}
</style>
