import { render, staticRenderFns } from "./planCard.vue?vue&type=template&id=2ab51adc&scoped=true&"
import script from "./planCard.vue?vue&type=script&lang=js&"
export * from "./planCard.vue?vue&type=script&lang=js&"
import style0 from "./planCard.vue?vue&type=style&index=0&id=2ab51adc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab51adc",
  null
  
)

export default component.exports