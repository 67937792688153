var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input_container"},[(_vm.label)?_c('label',{staticClass:"input_label",class:_vm.hasError ? 'hasError' : '',attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input_wrapper",class:{
      hasInlineButton: _vm.hasInlineButton,
    }},[(_vm.icon)?_c('img',{staticClass:"input_icon",attrs:{"src":_vm.getInputIcon(_vm.icon),"alt":"icon"}}):_vm._e(),_c('span',{staticClass:"input_fixed_value"},[_vm._v(" "+_vm._s(_vm.fixedValue)+" ")]),(_vm.icon)?_c('input',{staticClass:"custom_input",class:{
        hasError: _vm.hasError,
        disabled: _vm.disabled,
        hasInlineButton: _vm.hasInlineButton,
      },style:({
        'pointer-events': _vm.isSavedProduct ? 'none' : 'auto',
        color: _vm.isSavedProduct ? '#8C9196' : 'auto',
        background: _vm.isSavedProduct ? '#FAFBFB' : '#435160',
      }),attrs:{"type":_vm.type,"name":_vm.id,"id":_vm.id,"required":_vm.required,"placeholder":_vm.placeholder ? _vm.placeholder : '',"maxlength":_vm.maxlength ? _vm.maxlength : '',"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){_vm.updateValue($event.target.value), _vm.checkForContent($event)},"keyup":function($event){return _vm.$emit('check-input')}}}):_c('input',{staticClass:"custom_input no_icon",class:{
        hasError: _vm.hasError,
        disabled: _vm.disabled,
        hasInlineButton: _vm.hasInlineButton,
      },style:({
        'pointer-events': _vm.isSavedProduct ? 'none' : 'auto',
        color: _vm.isSavedProduct ? '#8C9196' : 'auto',
        background: _vm.isSavedProduct ? '#FAFBFB' : '#fff',
      }),attrs:{"type":_vm.type,"name":_vm.id,"id":_vm.id,"required":_vm.required,"placeholder":_vm.placeholder ? _vm.placeholder : '',"maxlength":_vm.maxlength ? _vm.maxlength : '',"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){_vm.updateValue($event.target.value), _vm.checkForContent($event)},"keyup":function($event){return _vm.$emit('check-input')}}}),(_vm.hasError)?_c('span',{staticClass:"input_hint",class:_vm.hasError ? 'hasError' : ''},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }