<template>

      <div  class="row ">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="changepassword-box" style="width: 100%;">
            <div class="header">
              <span class="card-title">Change Password</span>
            </div>
            <div class="body">
              <span class="form-error" v-if="hasChangePasswordError">{{
                changePasswordError
              }}</span>
              <span class="form-success" v-if="changePasswordSuccess">Password changed successfully!</span>
              <div class="form">
                <CustomPasswordInput type="password" id="password" label="Your Old Password" v-model="password.old" />
                <CustomPasswordInput type="password" id="password" label="Your New Password" v-model="password.new" />
                <CustomPasswordInput type="password" id="repeat-password" label="Repeat Password" v-model="password.repeat" />
              </div>
              <div class="d-flex justify-content-center">
                <button class="vgang-button text-center outlined" @click="changePassword">
                  Change password
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="edit-profile-box"><div class="header" style="padding: 15px;">
              <span class="card-title">Business Setting</span>

              <button :disabled="!allInputAreValid" :style="{
                cursor: !allInputAreValid ? 'not-allowed' : 'pointer',
              }" class="vgang_btn btn_green outlined" @click="updateAccountInfo($event)">
                Save changes
              </button>
            </div>
            <div class="body">
              <div class="custom-inputs-wrapper body-inputs-setting">
                <CustomInputV2 type="text" ref="storeName" id="storeName" label="Store Name" :required="true"
                  v-model="accountInfo.storeName" />
                <CustomInputV2 type="text" ref="storeUrl" id="storeUrl" label="Store URL" :required="true"
                  v-model="accountInfo.storeUrl" />
                <div class="inline-inputs">
                  <div class="inline-input-container" style="width: 250px; margin-right: 12px">
                    <CustomInputV2 type="email" id="email" label="Email" :required="true" v-model="accountInfo.email"
                      disabled="true" />
                  </div>
                  <div class="inline-input-container" style="width: 250px">
                    <CustomInputV2 ref="phonenumber" type="tel" id="phonenumber" label="Phone number" :required="true"
                      v-model="accountInfo.phoneNumber" />
                  </div>
                </div>
              </div>
              <div class="account-info">
                <span class="key">Default currency:
                  <span class="value">{{
                    accountInfo.currency ? accountInfo.currency : "Undefined"
                  }}</span></span>
                <!-- <div class="plan-info">
                  <span class="key">Current plan:
                    <span class="value">
                      {{
                        accountInfo.currentPlan
                        ? accountInfo.currentPlan.name
                        : "Undefined"
                      }}
                    </span>
                  </span>
                  <PlanSubUpgradeModal v-if="Object.keys(accountInfo).length &&
                      accountInfo.nextPlan.order &&
                      accountInfo.currentPlan.order < availablePlans.length
                      " :plan="accountInfo.nextPlan" :key="accountInfo.nextPlan.order + 1"
                    :upgradePlanDialog="upgradePlanDialog" @closeUpgradePlanDialog="upgradePlanDialog = false"
                    @OpenUpgradePlan="upgradePlanDialog = true" @planUpgraded="getAccountInfo()">
                    Upgrade to
                    {{
                      accountInfo.nextPlan
                      ? accountInfo.nextPlan.name
                      : "Undefined"
                    }}
                    Plan
                  </PlanSubUpgradeModal>
                </div> -->
              </div>
              <div v-if="!isShopify" class="logout-container">
                <span class="logout" @click="handleLogout"><span class="material-icons icon"> logout </span>Logout</span>
              </div>
              <div v-else class="logout-container" @click="handleDiscount">
                <span class="logout">
                  <span class="material-icons icon"> logout </span>Disconnect
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!$route.path.includes('/sh-retailer')" class="col-sm-12 col-md-12 col-lg-6">
          <div class="connect-shopify-box">
            <div class="header">
              <span class="card-title">Connect your Shopify store</span>
              <span class="card-subtitle">Your Store Name and URL may be different. Be sure to refer to
                the URL, and not the Store Name.</span>
            </div>
            <div class="body">
              <div class="form">
                <div class="shopify-url-input-wrapper">
                  <div class="shopify-input-container" style="flex: 1">
                    <CustomInputV2 type="text" id="shopify-url" label="Shopify url" v-model="shopifyURL"
                      fixedValue=".myshopify.com" :disabled="shopInstalled" />
                  </div>
                  <button v-if="!shopInstalled" class="vgang-button button" @click="connectShop()"
                    :disabled="IntegrationButtonIsDisabled" :class="IntegrationButtonIsDisabled ? 'disabled' : ''">
                    Save & Install
                  </button>
                  <button v-else class="vgang-button button success-deactive-button">
                    Installed Successfuly
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


</template>

<script>
// Services
import SettingService from "@/services/SettingService";
import RegisterationSetup from "@/services/RegisterationSetup";
// Services
import AuthService from "@/services/AuthenticationService";
// import CommonData from "@/services/CommonData";
import PlanSubUpgradeModal from "@/components/retailer/modals/planSubUpgradeModal.vue";
import CustomInputV2 from "@/components/CustomInputV2.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
import MaskedInput from "@/MaskedInput.js";
import { removeVariable, getVariable } from "@/utils/conditionalStoreVariables";
import createApp from "@shopify/app-bridge";
import { mapGetters, mapActions } from "vuex";
import { getSessionToken } from "@shopify/app-bridge-utils";
export default {
  name: "AccountRetailer",
  components: {
    CustomPasswordInput,
    CustomInputV2,
    CustomPasswordInput,
    MaskedInput,
    PlanSubUpgradeModal,
  },
  watch: {
    accountInfo: {
      deep: true, // Enable deep watching
      immediate: true,
      handler(newValue, oldValue) {
        if (
          !this.isValidPhoneNumber(newValue.phoneNumber) ||
          !newValue.storeName ||
          !newValue.storeUrl
        ) {
          this.allInputAreValid = false;
        } else {
          this.allInputAreValid = true;
        }
      },
    },
  },
  data() {
    return {
      
      password: {
        old: "",
        new: "",
        repeat: "",
      },
      hasChangePasswordError: false,
      changePasswordError: "",
      changePasswordSuccess: false,
    
  
      isShopify: false,
      allInputAreValid: Boolean,
      accountInfo: {
        storeName: "",
        storeUrl: "",
        email: "",
        phoneNumber: "",
        currency: "",
        currentPlan: {
          name: "",
          price: "",
        },
        nextPlan: {
          name: "",
          price: "",
        },
      },
      upgradePlanDialog: false,
      shopifyURL: "",
      shopInstalled: false,
      IntegrationButtonIsDisabled: false,
    };
  },
  async created() {
    await this.getAccountInfo();
  },
  computed: {
    ...mapGetters({
      availablePlans: "retailer/listOfPlansSubscription",
      newAlert: "alert/newAlert",
      alertError: "alert/alertError",
    }),
  },
  methods: {
    ...mapActions([
      "retailer/getCurrentUserInformation",
      "retailer/getCurrentUserAccountInformation",
      "alert/clear",
      "alert/newAlert",
    ]),
    closeUpgradePlanDialog() {
      this.upgradePlanDialog = false;
    },
    changePassword() {
      if (!this.password.old || !this.password.new) {
        this.changePasswordError =
          "Your New Password or Repeat Password is empty!";
        this.changePasswordSuccess = false;
        this.hasChangePasswordError = true;
      } else {
        const params = {
          oldPassword: this.password.old,
          newPassword: this.password.new,
          repeatNewPassword: this.password.repeat,
        };
        SettingService.changePassword(params)
          .then((response) => {
            console.log(response);
            this.hasChangePasswordError = false;
            this.changePasswordSuccess = true;
          })
          .catch((e) => {
            if (e.data.validations) {
              this.changePasswordError =
                "Your New Password or repeat password is empty or doesn't match!";
              this.changePasswordSuccess = false;
              this.hasChangePasswordError = true;
            } else {
              this.changePasswordError = e.data.message;
              this.changePasswordSuccess = false;
              this.hasChangePasswordError = true;
            }
          });
      }
    },
    async getAccountInfo() {
      try {
        this.accountInfo = await this[
          "retailer/getCurrentUserAccountInformation"
        ](true);
        this.shopifyURL = this.accountInfo.shopUrl;
      } catch (error) {
        console.log(error);
      }
    },
    isValidPhoneNumber(phoneNumber) {
      // Define a regular expression for a common phone number format (U.S. format)

      return /^\+?\d+$/.test(phoneNumber);
    },
    updateAccountInfo(event) {
      if (
        this.accountInfo.storeName &&
        this.accountInfo.storeUrl &&
        this.accountInfo.phoneNumber
      ) {
        if (!this.accountInfo.storeUrl.includes("https://")) {
          this.accountInfo.storeUrl = "https://" + this.accountInfo.storeUrl;
        }

        const params = {
          storeName: this.accountInfo.storeName,
          storeUrl: this.accountInfo.storeUrl,
          phoneNumber: this.accountInfo.phoneNumber,
        };
        SettingService.updateAccountInfo(params)
          .then((response) => {
            this.accountInfo = response.data;
            this.getAccountInfo();

            this["alert/newAlert"]({
              type: "success",
              message: "Your data has been updated successfully",
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          })
          .catch((e) => {
            if (e.data.validations) {
              this["alert/newAlert"]({
                type: "error",
                message: "Your field are invalid or empty!",
              });
              setTimeout(async () => {
                this.showAlertError = false;
                this["alert/clear"]();
              }, 3000);
            } else {
              this["alert/newAlert"]({
                type: "error",
                message: e.data.message,
              });
              setTimeout(async () => {
                this.showAlertError = false;
                this["alert/clear"]();
              }, 3000);
            }
          });
      } else {
        if (!this.accountInfo.storeName)
          this.$refs.storeName.checkForContent(event);

        if (!this.accountInfo.storeUrl)
          this.$refs.storeUrl.checkForContent(event);

        if (!this.accountInfo.phoneNumber)
          this.$refs.phonenumber.checkForContent(event);
      }
    },
    handleLogout() {
      removeVariable("role");
      this.message = "";
      this.submitted = true;
      this.$store.dispatch("auth/logout").then(
        (data) => {
          this.$router.go({ name: "LoginSupplier" });
          this.message = data.message;
          this.successful = true;
          removeVariable("user");
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },

    connectShop() {
      const params = {
        storeUrl: this.shopifyURL,
        platformName: "shopifyfeeder",
      };
      RegisterationSetup.installLinkRetailer(params)
        .then((response) => {
          this.redirectURL = response.data.redirectUrl;
          window.location.replace(this.redirectURL);
        })
        .catch((e) => {
          if (e.data.validations) {
            this["alert/newAlert"]({
              type: "error",
              message: "Your field are invalid or empty!",
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          } else {
            this["alert/newAlert"]({
              type: "error",
              message: e.data.message,
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          }
        });
    },
    upgradePlan() {
      // handle the token
      SettingService.getClientSecret(purchaseID)
        .then((response) => {
          console.log(response);
          const clientSecret = response.data.data.clientSecret;
          groupComponent.instance
            .confirmCardPayment(clientSecret, {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name: this.CurrentUserInformation.email,
                },
              },
            })
            .then(async (result) => {
              if (result.error) {
                let displayError = document.getElementById(
                  "card-element-errors"
                );
                displayError.textContent = result.error.message;
                this.downgradeLoading = false;
              } else {
                setTimeout(async () => {
                  await this["retailer/getPlansSubscription"]();
                  this.downgradeLoading = false;
                  this.$emit("closeDowngradePlanDialog", false);
                }, 2500);
              }
            })
            .catch((e) => {
              this["alert/newAlert"]({
                type: "error",
                message: e.data.message,
              });
              setTimeout(async () => {
                this.showAlertError = false;
                this["alert/clear"]();
              }, 3000);
              this.downgradeLoading = false;
            });
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
          this.downgradeLoading = false;
        });
    },
    async handleDiscount() {
      try {
        const accessToken = await this.initiateAppBridge();

        const response = await AuthService.retailerUnAssignUser(accessToken);

        if (response.status === 200) {
          this.$router.push("/sh-retailer/welcome-page");
        }
      } catch (error) {
        this["alert/newAlert"]({
          type: "error",
          message: error.data.message,
        });
        setTimeout(async () => {
          this.showAlertError = false;
          this["alert/clear"]();
        }, 3000);
        console.log(error);
      }
    },
    async initiateAppBridge() {
      const apiKey = process.env.VUE_APP_SHOPIFY_RETAILER_API_KEY;
      const host = await getVariable("host");

      const app = createApp({
        apiKey: apiKey,
        host: host,
      });

      try {
        const response = await getSessionToken(app);

        return response;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    if (
      window.self !== window.top &&
      process.env.VUE_APP_SOURCE_NAME == "shopifyimporter"
    ) {
      this.isShopify = true;
    }
  },
};
</script>

<style lang="scss">
.account-retailer {
  .vgang-btn-green-box {
    width: auto !important;
  }

  .key {
    margin-top: 21px;
  }

  .shopify-input-container {
    width: 100%;
  }

  .inline-inputs {
    @include sm {
      flex-direction: column;

      .inline-input-container {
        width: 100% !important;
      }
    }
  }

  .shopify-url-input-wrapper {
    @include md {
      flex-direction: column !important;
    }
  }
}
</style>
