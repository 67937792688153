<template>
  <div
    class="membership-section"
    :class="{
      'justify-content-center':
        userProfile.payment_method &&
        currentUserAccountInformation.currentPlan.name == 'Starter',
    }"
  >
    <div class="plans-section">
      <div class="plan-box">
        <div class="header">
          <span class="card-title">Your plan</span>
        </div>
        <div class="body">
          <p class="plan-title">
            {{ currentUserAccountInformation.currentPlan.name }}
          </p>
          <ul
            v-if="currentUserAccountInformation.currentPlan.support"
            class="plans-futures"
          >
            <li
              v-for="feature in currentUserAccountInformation.currentPlan.support.split(
                ','
              )"
              :key="feature"
              class="future"
            >
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
      <!-- <div
        v-if="currentUserAccountInformation.nextPlan?.id != 0"
        class="plan-box plan-upgrade mx-4"
      >
        <div class="header">
          <span class="card-title">
            Upgrade to {{ currentUserAccountInformation.nextPlan.name }} plan to
            get
          </span>
        </div>
        <div class="body py-5">
          <ul
            v-if="currentUserAccountInformation.nextPlan.support"
            class="plans-futures"
          >
            <li
              v-for="feature in currentUserAccountInformation.nextPlan.support.split(
                ','
              )"
              :key="feature"
              class="future"
            >
              {{ feature }}
            </li>
          </ul>
       <PButton
            @click="upgradePlanDialog = !upgradePlanDialog"
            primary
            class="checkout-btn-plan"
          >
            Upgrade Plan
          </PButton> 
        </div>
      </div> -->
    </div>
    <div
      class="payment-section"
      :style="{
        'display:none':
          userProfile.payment_method &&
          currentUserAccountInformation.currentPlan.name == 'Starter',
      }"
    >
    <div v-if="!userProfile.payment_method" class="membership-box">
        <div class="header">
          <span class="card-title">Your payment info</span>
        </div>
        <div class="body">
          <p class="desc">
            To process your orders, you need to provide your payment information
            first. We collect commission only after your customers received
            their orders.
          </p>
          <PButton @click="completeStripeInfo()" class="checkout-btn">
            Complete your payment info
          </PButton>
        </div>
      </div>
      <!-- <div class="membership-box">
        <div class="header">
          <span v-fi="currentUserAccountInformation.currentPlan.name !== 'Starter'" class="card-title">Cancel subscription</span>
        </div>
        <div class="body">
          <p class="desc">
            Pausing your subscription? With our Basic plan, you can still keep all your imported products and receive orders. We'll simply collect a 5% commission on any sales made. You can resume your subscription anytime.
          </p>
          <PButton @click="cancelMembership" class="checkout-btn">
            Cancel subscription
          </PButton>
        </div>
      </div> -->



      <div
        v-if="currentUserAccountInformation.currentPlan.name !== 'Starter'"
        class="membership-box" style="height: 100% !important;"
      >
        <div class="header">
          <span class="card-title">Cancel subscription</span>
        </div>
        <div class="body">
          <p class="desc">
            Pausing your subscription? With our Basic plan, you can still keep
            all your imported products and receive orders. We'll simply collect
            a 5% commission on any sales made. You can resume your subscription
            anytime.
          </p>
          <PlanSubDowngradeModal
            :isCurrentPlanExpired="true"
            :isCancelMembershipDefault="true"
            :plan="currentUserAccountInformation.currentPlan"
            :key="currentUserAccountInformation.currentPlan.order"
            :downgradePlanDialog="downgradePlanDialog"
            @closeDowngradePlanDialog="downgradePlanDialog = false"
            @OpenDowngradePlan="downgradePlanDialog = true"
          >
          <button  type="button" class="checkout-btn Polaris-Button" ><span   class="Polaris-Button__Content"><span class="Polaris-Button__Text"> Cancel subscription </span></span></button>
          </PlanSubDowngradeModal>
        </div>
      </div>
    </div>
    <!-- <PlanSubUpgradeModal
      v-if="
        Object.keys(currentUserAccountInformation).length &&
        currentUserAccountInformation.nextPlan.order &&
        upgradePlanDialog
      "
      :showButton="false"
      :headerBtn="true"
      :plan="currentUserAccountInformation.nextPlan"
      :key="currentUserAccountInformation.nextPlan.order"
      :upgradePlanDialog="upgradePlanDialog"
      @closeUpgradePlanDialog="upgradePlanDialog = false"
      @planUpgraded="getAccountInfo()"
    >
    </PlanSubUpgradeModal> -->
  </div>
</template>

<script>
// Components
import PlanSubUpgradeModal from "@/components/retailer/modals/planSubUpgradeModal.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
import PlanSubDowngradeModal from "@/components/retailer/modals/planSubDowngradeModal.vue";
import SettingService from "@/services/SettingService";
import { mapGetters, mapActions } from "vuex";
import CommonData from "@/services/CommonData";
export default {
  components: {
    CustomPasswordInput,
    PlanSubUpgradeModal,
    PlanSubDowngradeModal,
  },
  data() {
    return {
      upgradePlanDialog: false,
      downgradePlanDialog: false,
      userPlan: null,
      userProfile: {},
      stripeLink: null,
    };
  },

  async created() {
    CommonData.getStripeLink()
      .then((response) => {
        this.stripeLink = response.data.link.url;
      })
      .catch((e) => {
        this["alert/newAlert"]({
          type: "error",
          message: e.data.message,
        });
        setTimeout(async () => {
          this.showAlertError = false;
          this["alert/clear"]();
        }, 3000);
      });
    try {
      await this["retailer/getPlansSubscription"]();
      await this["retailer/getCurrentUserAccountInformation"](false);
      await this.getCurrentAccountInfo();
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    this.getUserProfile();
  },
  methods: {
    ...mapActions([
      "alert/clear",
      "alert/newAlert",
      "retailer/getPlansSubscription",
      "retailer/getCurrentUserAccountInformation",
    ]),
    getUserProfile() {
      CommonData.getCurrentUser()
        .then((response) => {
          this.userProfile = response.data;
        })
        .catch((e) => {
          console.log(e);
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },

    cancelMembership() {
      SettingService.cancelMembership()
        .then((response) => {
          this["alert/newAlert"]({
              type: "success",
              message: response?.data?.message,
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
        })
        .catch((e) => {
          this["alert/newAlert"]({
              type: "error",
              message: e?.data?.message,
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
        });
    },

    completeStripeInfo() {
      if (!this.userProfile.payment_method) {
        this.forwardToStripeLink();
      }
    },
    forwardToStripeLink() {
      CommonData.getStripeLink()
        .then((response) => {
          this.stripeLink = response.data.link.url;
          if (this.stripeLink == "") {
            this["alert/newAlert"]({
              type: "error",
              message: "Oops! Something went wrong. Please try again later. ",
            });
            setTimeout(async () => {
              this.showAlertError = false;
              this["alert/clear"]();
            }, 3000);
          } else {
            window.open(
              this.stripeLink,
              "_blank" // <- This is what makes it open in a new window.
            );
          }
          window.addEventListener("focus", () => this.getUserProfile());
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },
    getAccountInfo() {
      try {
        this["retailer/getCurrentUserAccountInformation"](true);
      } catch (error) {
        this["alert/newAlert"]({
          type: "error",
          message: e.data.message,
        });
        setTimeout(async () => {
          this.showAlertError = false;
          this["alert/clear"]();
        }, 3000);
      }
    },
    async getCurrentAccountInfo() {
      try {
        this.userPlan = await this["retailer/getCurrentUserAccountInformation"](
          true
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  computed: {
    ...mapGetters({
      availablePlans: "retailer/listOfPlansSubscription",
      currentUserAccountInformation: "retailer/currentUserAccountInformation",
      listOfProductsPagination: "retailer/listOfProductsPagination",
    }),
  },
};
</script>

<style lang="scss" scoped>
.membership-section {
  column-gap: 18px;
  display: flex;
  @include md {
    flex-direction: column;
  }

  .plans-section {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 0px 4px rgba(16, 24, 40, 0.06);
    padding-bottom: 24px;
    height: auto;
    width: 50%;
    @include md {
      width: 100%;
      margin-bottom: 18px;
    }
    .plan-box,
    .payment-section {
      height: auto !important;
    }
  }
  .plan-box {
    background-color: unset;
    box-shadow: unset !important;
  }
  .plan-upgrade {
    background: #f1f8f5 !important;
  }
  .payment-section {
    row-gap: 18px;
    display: flex;
    flex-direction: column;
    width: 50%;
    @include md {
      width: 100%;
    }
  }

  .plan-title {
    color: #6d7175;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .membership-box,
  .plan-box {
    border-radius: 10px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 0px 4px rgba(16, 24, 40, 0.06);
    height: 50%;
    background-color: #fff;
    display: block;
    margin: 0;

    .plans-futures {
      .future {
        color: #6d7175;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: unset !important;
      padding: 24px;

      .card-title {
        font-stretch: normal;
        letter-spacing: normal;
        margin: 0;
        color: #202223;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .body {
      padding: 24px;
      padding-top: 0;
      padding-bottom: 0;

      .desc {
        color: #202223;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }
      .cancel-sub {
        color: #2c6ecb;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-decoration-line: underline;
        margin-top: 21px;
        margin-bottom: 24px;
      }

      .checkout-btn-plan {
        margin-top: 14px;
        margin-bottom: 14px;
      }
      .checkout-btn {
        margin-top: 21px;
        margin-bottom: 24px;
        @include md {
          margin-bottom: 21px;
        }
      }
    }
  }
}
</style>
