<template>
  <main>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      class="retailer-sidebar"
      fixed
      disable-resize-watcher
      hide-overlay
      permanent
      v-if="!hideNavigations"
      mini-variant-width="72"
    >
      <v-list-item v-if="isLargeScreen" class="px-2 sidebar__logo">
        <v-list-item-avatar class="justify-end">
          <router-link to="/">
            <v-img
              class="lg_logo mr-auto"
              src="@/assets/img/logo_large.svg"
            ></v-img>
          </router-link>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item v-else class="px-2">
        <v-list-item-avatar>
          <router-link to="/">
            <v-img class="sm_logo" src="@/assets/img/logo.svg"></v-img>
          </router-link>
        </v-list-item-avatar>
      </v-list-item>

      <v-divider></v-divider>
      <v-list class="top-list-sidebar">
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" router>
          <v-list-item-action>
            <span class="material-symbols-outlined" style="font-size: 26px">
              {{ item.icon }}
            </span>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template #append>
        <v-list class="bottom-list-sidebar">
          <v-list-item
            v-for="(item, i) in bottom_items"
            :key="i"
            :to="item.to"
            router
            exact
          >
            <v-list-item-action>
              <span style="font-size: 26px" class="material-symbols-outlined">
                {{ item.icon }}
              </span>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      absolute
      app
      class="app-bar"
      :class="{ isOpen: miniVariant }"
      v-if="!hideNavigations"
    >
      <v-btn
        icon
        v-if="showMiniVariantBtn"
        @click.stop="miniVariant = !miniVariant"
      >
        <span style="font-size: 26px" class="material-symbols-outlined"
          >{{ `chevron_${miniVariant ? "right" : "left"}` }}
        </span>
      </v-btn>
      <SearchBtnV2
        placeholder="Search a product "
        :maxWidth="'350px'"
        :searchText="routeSearchQuery"
        @search="Search($event)"
      />

      <v-spacer />
      <!-- <button
        v-if="currentUserAccountInformation.nextPlan"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        disabled
        class="vgang_btn btn_green p-3"
      @click="upgradePlanDialog = false"
      >
        <img
          src="@/assets/img/heart.png"
          alt="outline heart"
          class="subUpgrade__icon"
        />

        Upgrade your Plan header
      </button> -->
      <!-- <PlanSubUpgradeModal
        v-if="
          Object.keys(currentUserAccountInformation).length &&
          currentUserAccountInformation.nextPlan &&
          upgradePlanDialog
        "
        :showButton="false"
        :headerBtn="true"
        :plan="currentUserAccountInformation.nextPlan"
        :key="currentUserAccountInformation.nextPlan.order"
        :upgradePlanDialog="upgradePlanDialog"
        @closeUpgradePlanDialog="upgradePlanDialog = false"
        @planUpgraded="getAccountInfo()"
      >
      </PlanSubUpgradeModal> -->
      <span class="ml-4">
        <button
          style="padding: 10px; font-weight: 600"
          class="custom-button filled"
          @click="handleLogout"
        >
          Logout
        </button>
      </span>
    </v-app-bar>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PlanSubUpgradeModal from "@/components/retailer/modals/planSubUpgradeModal.vue";
import SearchBtnV2 from "@/components/bases/SearchBtnV2.vue";
import { removeVariable } from "@/utils/conditionalStoreVariables";

export default {
  name: "RetailerNavigator",
  components: {
    PlanSubUpgradeModal,
    SearchBtnV2,
  },
  data() {
    return {
      miniVariant: true,
      userType: "retailer",
      hideNavigations: false,
      clipped: false,
      drawer: true,
      fixed: false,
      showMiniVariantBtn: true,
      width: window.innerWidth,
      isLargeScreen: Boolean,
      upgradePlanDialog: false,

      items: [
        {
          icon: "list",
          title: "List of products",
          to: "/retailer/home",
        },
        {
          icon: "swap_horiz",
          title: "Import List",
          to: "/retailer/import-list",
        },
        {
          icon: "store",
          title: "My Products",
          to: "/retailer/my-products",
        },

        {
          icon: "loyalty",
          title: "Checkout",
          to: "/retailer/checkout",
        },
      ],
      bottom_items: [
        {
          icon: "settings",
          title: "Setting",
          to: "/retailer/setting",
        },
      ],
      right: false,
      rightDrawer: false,
      title: "Vgang",
    };
  },

  async created() {
    window.addEventListener("resize", this.onResize);

    this.onResize();
    try {
      await this["retailer/getPlansSubscription"]();
      await this["retailer/getCurrentUserAccountInformation"](false);
    } catch (error) {
      console.log(error);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },

  computed: {
    ...mapGetters({
      availablePlans: "retailer/listOfPlansSubscription",
      currentUserAccountInformation: "retailer/currentUserAccountInformation",
      listOfProductsPagination: "retailer/listOfProductsPagination",
    }),
    routeSearchQuery() {
      return this.$route.query.search;
    },
  },

  methods: {
    handleLogout() {
      removeVariable("role");

      this.$store.dispatch("auth/logout").then(
        (data) => {
          this.$router.go({ name: "LoginSupplier" });

          localStorage.removeItem("user");
          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    ...mapActions([
      "retailer/getPlansSubscription",
      "retailer/getCurrentUserAccountInformation",
    ]),

    closeUpgradePlanDialog() {
      this.upgradePlanDialog = false;
    },
    onResize(e) {
      this.width = window.innerWidth;
      if (this.width >= 1625) {
        this.isLargeScreen = true;
        this.miniVariant = false;
        this.showMiniVariantBtn = false;
      } else {
        this.isLargeScreen = false;
      }
    },
    getAccountInfo() {
      try {
        this["retailer/getCurrentUserAccountInformation"](true);
      } catch (error) {
        console.log(error);
      }
    },
    async Search(event) {
      if (event) {
        this.$router.push({
          name: "Product list",
          query: {
            category: this.$route.query.category,
            search: event,
            country: this.$route.query.country,
            sort: this.$route.query.sort,
            tag: this.$route.query.tag,
            shipping_time: this.$route.query.shipping_time,
            dont_show_out_of_stock: this.$route.query.dont_show_out_of_stock,
          },
        });
      } else {
        this.$router.push({
          name: "Product list",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__avatar {
  width: 100% !important;
  .lg_logo {
    width: 43%;
  }
}

.plan {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1d2939;
  margin-right: 24px;
}

.subUpgrade__icon {
  margin-right: 10px;
}
</style>
